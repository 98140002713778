import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

const AppSidebar = (props) => {
    const pathname = window?.location?.pathname;
    // const role = props?.loggedInUser?.role?._id?.name?.trim()?.toLowerCase();
    const role = props.loggedInUser?.role?._id
    const roleName = role?.name?.trim()?.toLowerCase()
    console.log(roleName, "rolerolerole")
    const permissions = role?.active && role?.permissions || {};
    const {
        dashboard = {},
        upload_data = {},
        order = {},
        report = {},
        user_management = {},
        reach_us = {},
        department = {},
        plan = {},
        manifest = {},
        weight_discrepancy = {},
        pin_code_search = {},
        role_management = {},
        billing_and_wallet = {},
        remittance = {},
    } = permissions;

    return (
        <div className={props.showSideBar ? "vertical-menu side_menu open" : " vertical-menu side_menu"}>
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">


                        {
                            dashboard?.show &&
                            <li className={pathname === '/cargo/cargo_dash' ? 'mm-active' : undefined} onClick={props.closeSideBar}>
                                <Link to="/cargo/cargo_dash" className="waves-effect">
                                    <i className="ti ti-home"></i>
                                    <span key="t-dashboards">Dashboard</span>
                                </Link>
                            </li>
                        }

                        <Accordion>
                            {
                                // (role === 'admin' || role === 'administrator' || role === 'vendor') && <>
                                // (upload_data?.show || upload_data?.master?.show || upload_data?.courier_partner?.show) &&
                                (roleName === 'admin' || roleName === 'administrator') &&
                                <>

                                    <Accordion.Toggle as={Card.Header} eventKey="11">
                                        <li className={`${(
                                                pathname === '/cargo/uploadShipment'
                                            )
                                                ? 'mm-active' : undefined
                                            }`}>
                                            <a
                                                href="javascript: void(0);"
                                                className={`has-arrow `}
                                            >
                                                <i className="dripicons dripicons-upload"></i>
                                                <span key="t-layouts"> Upload Data</span>
                                            </a>
                                        </li>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="11">
                                        <ul className="sub-menu" aria-expanded="false">
                                            {
                                                // (role === 'admin' || role === 'administrator') &&
                                                upload_data?.master?.show &&
                                                <li className={pathname === '/cargo/uploadShipment' ? 'mm-active' : undefined} onClick={props.closeSideBar}>
                                                    <Link to="/cargo/uploadShipment">
                                                        Upload Data
                                                    </Link>
                                                </li>
                                            }


                                        </ul>
                                    </Accordion.Collapse>
                                </>
                            }


                            {
                               
                                <>
                                    <Accordion.Toggle as={Card.Header} eventKey="10">
                                        <li className={`${(
                                            pathname === '/cargo/shipmentList' ||  pathname === '/cargo/appointment'

                                        )
                                            ? 'mm-active' : undefined
                                            }`}
                                        >
                                            <Link
                                                className={`has-arrow `}
                                            >
                                                <i className="fa fa-truck"></i>
                                                <span key="t-layouts"> Shipment</span>
                                            </Link>
                                        </li>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey="10">
                                        <ul className="sub-menu" aria-expanded="false">


                                            {
                                                order?.order_dashboard?.show &&
                                                <li className={pathname === '/cargo/shipmentList' ? 'mm-active' : undefined} onClick={props.closeSideBar}>
                                                    <Link to="/cargo/shipmentList">All Shipments</Link>
                                                </li>
                                            }


                                        </ul>
                                    </Accordion.Collapse>
                                    <Accordion.Collapse eventKey="10">
                                        <ul className="sub-menu" aria-expanded="false">


                                            {
                                                order?.order_dashboard?.show &&
                                                <li className={pathname === '/cargo/appointment' ? 'mm-active' : undefined} onClick={props.closeSideBar}>
                                                    <Link to="/cargo/appointment">Appointment Delivery</Link>
                                                </li>
                                            }


                                        </ul>
                                    </Accordion.Collapse>
                                    
                                </>
                            }


                            {
                               (roleName === 'admin' || roleName === 'administrator') && 
                                <>
                                    <Accordion.Toggle as={Card.Header} eventKey="12">
                                        <li className={`${(
                                            pathname === '/cargo/serviceability'

                                        )
                                            ? 'mm-active' : undefined
                                            }`}
                                        >
                                            <Link
                                                className={`has-arrow `}
                                            >
                                                <i className="fa fa-window-restore"></i>
                                                <span key="t-layouts">Serviceability</span>
                                            </Link>
                                        </li>
                                    </Accordion.Toggle>


                                    <Accordion.Collapse eventKey="12">
                                        <ul className="sub-menu" aria-expanded="false">

                                            {
                                                order?.order_dashboard?.show &&
                                                <li className={pathname === '/cargo/serviceability' ? 'mm-active' : undefined} onClick={props.closeSideBar}>
                                                    <Link to="/cargo/serviceability">Serviceability List</Link>
                                                </li>
                                            }

                                        </ul>
                                    </Accordion.Collapse>
                                    
                                </>
                            }
                            {/* {
                            (roleName === 'admin' || roleName === 'administrator') && 
                            <>
                                <Accordion.Toggle as={Card.Header} eventKey="13">
                                    <li className={`${pathname === '/cargo/appointment' ? 'mm-active' : undefined}`}>
                                        <Link className="has-arrow">
                                            <i className="far fa-calendar-check"></i>
                                            <span key="t-layouts">ABD List</span>
                                        </Link>
                                    </li>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="13">
                                    <ul className="sub-menu" aria-expanded="false">
                                        {
                                            order?.order_dashboard?.show &&
                                            <li className={pathname === '/cargo/appointment' ? 'mm-active' : undefined} onClick={props.closeSideBar}>
                                                <Link to="/cargo/appointment">ABD List</Link>
                                            </li>
                                        }
                                    </ul>
                                </Accordion.Collapse>
                            </>
                            } */}
                        </Accordion>


                    </ul>
                </div>
            </div>
        </div>
    );
};
export default AppSidebar;
