import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
// import { selectOrdersRequest, trackOrderRequest, cancelOrderRequest } from "../Duck/OrderActions";
import appUrl from "../../Constants/AppUrl";
import { showNotification } from "../../Utils/CommonFunctions";
import axios from "axios";
import fileDownload from 'js-file-download';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Tippy from '@tippyjs/react';
import { selectOrdersRequest, trackOrderRequest, cancelOrderRequest } from "../../Order/Duck/OrderActions";

const B2BOrderItem = (props = {}) => {
    const [state, setState] = useState({
        downloading: false
    });
    const role = props.loggedInUser?.role?._id
    const roleName = role?.name?.trim()?.toLowerCase()
    const { downloading } = state;
    let { index = 0, orderId, order, history, printSelection = [], loggedInUser, showTicketStatus } = props;
    let isSelected = printSelection?.includes(orderId);

    console.log("order", order.fullStatus);

    if (!order) {
        return <></>;
    }
    const pastDate = new Date(order?.edd);
    const currentDate = new Date();
    console.log(pastDate, currentDate, "currentDate")


    if (pastDate < currentDate) {
        let diffInMs = currentDate - pastDate;
        let diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        console.log(diffInDays, "currentDatecurrentDate")
    }

    const onShowOrderSummary = () => {
        let path = order?.bulk_id && !transaction ? `/app/bulkShipmentList/${order?.bulk_id?._id}` : `/app/individualShipment/${orderId}`;
        history?.push(path);
    };

    const onRefreshOrder = (e) => {
        e.stopPropagation()
        let { _id, waybillNo } = order;
        if (_id && waybillNo) {
            let params = {
                order_id: _id,
                airwaybilno: waybillNo,
            };

            props.trackOrder && props.trackOrder(params);
        }
    }

    const onDownloadPendingOrders = (e) => {
        if (!downloading) {
            setState({ downloading: true });

            axios({
                method: "GET",
                url: `${appUrl.ORDERS_URL}/bulk-download/pending`,
                params: { bulk_id: order?.bulk_id?._id },
                contentType: 'application/doc; charset=utf-8',
                responseType: 'arraybuffer',
            })
                .then(res => {
                    let fileName = `Bulk-Pending-${moment().format("DD-MM-YYYY")}.xlsx`;
                    let mineType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                    fileDownload(res.data, fileName, mineType);
                    setState({ isLoading: false });
                })
                .catch(err => {
                    let resData = String.fromCharCode.apply(null, new Uint8Array(err.response.data));
                    showNotification("error", resData?.meta?.message || resData?.message || "Error in Download file");
                    console.log("onDownloadOrder ", resData);
                    setState({ isLoading: false });
                });
        }
    }

    const onCancelOrder = (e) => {
        e.stopPropagation()
        let { _id, waybillNo } = order;
        if (_id && waybillNo) {
            let params = {
                order_id: _id,
                airwaybilno: waybillNo,
            };

            props.handleCancelOrderModal && props.handleCancelOrderModal(true, params);
        }
    }

    const onCreateRtoOrder = (e) => {
        e.stopPropagation()
        let { _id, waybillNo } = order;
        if (_id && waybillNo) {
            let params = { order_id: _id };

            props.handleCreateRtoOrderModal && props.handleCreateRtoOrderModal(true, params);
        }
    }

    const onShowOrderInvoice = (e) => {
        e.stopPropagation();
        history?.push(`/app/individualinvoice/${orderId}`);
    }

    const onShowTicket = (e) => {
        e.stopPropagation();
      

          const status = order?.fullStatus; // Assume "Delivered On Time" as an example
          const url = `/OrderActivity/${order?.waybillNo}?status=${encodeURIComponent(status)}`;
          window.open(url);
    }

    const onPrint = (e) => {
        e.stopPropagation();
        history?.push(`/app/ShipmentPrintModal/${orderId}`);
        // props.togglePreview &&
        // props.togglePreview("603e1853b938c5789c7fcf99", [
        //     {
        //         _id: "603e1853b938c5789c7fcf99",
        //         filename: "Dispatch Label.png",
        //     },
        // ]);
    }

    const onSelect = (e) => {
        e.stopPropagation();
        props.selectOrders && props.selectOrders([orderId], !isSelected);
    }

    let transaction = order?.waybillNo;
    let orderStatus = Array.isArray(order?.orderStatus) && order.orderStatus.length > 0 && order.orderStatus[0];
    let scanDetail = Array.isArray(orderStatus?.scan_detail) && orderStatus.scan_detail?.length > 0 && orderStatus.scan_detail[orderStatus.scan_detail.length - 1];
    let deliveryDetail = Array.isArray(orderStatus?.scan_detail) && orderStatus.scan_detail?.length > 0 &&
        orderStatus?.scan_detail.find(s => s?.status?.toLowerCase().includes("delivered") && !s?.status?.toLowerCase().includes("undelivered"))
    let comments = order?.comments || [];
    let mLogistic;

    let selected_courier_tat = order?.available_logistic?.length > 0 && order.available_logistic[0]?.tat_score;
    let delivered_date = order?.delivery_date ? order?.delivery_date : moment();
    let date_created = transaction?.created_at && moment(moment.utc(transaction?.created_at).format('DD-MM-YYYY'), 'DD-MM-YYYY');
    let date_delivered = moment(moment.utc(delivered_date).format('DD-MM-YYYY'), 'DD-MM-YYYY');
    let last_deliver_tat = date_created && moment(date_created).add(Number(selected_courier_tat), "days");
    let date_gap = moment.duration(date_delivered.diff(date_created)).asDays();

    let orderRowBg;
    if (
        String(scanDetail?.status).trim().toUpperCase() === "UNDELIVERED" ||
        String(scanDetail?.status_description).trim().toUpperCase() === "UNDELIVERED" ||
        String(order?.latest_order_status).trim().toUpperCase() === "UNDELIVERED" ||
        (transaction && Number(date_gap) > Number(selected_courier_tat))
    ) {
        orderRowBg = "#f8d7da";
    } else if (comments?.length > 0) {
        orderRowBg = "#fff3cd";
    }

    if ((transaction || order?.waybillNo) && Array.isArray(order?.available_logistic) && order?.available_logistic?.length > 0) {
        mLogistic = order?.available_logistic[0];
    }

    return (
        <tr
            key={index}
            className={!order?.bulk_id || (order?.bulk_id && transaction) ? "" : ""}
            style={{ background: orderRowBg }}
        >
            {/* {
                props.showSelect &&
                <td className="text-center">
                    { !transaction ?
                        <Tippy content="Unable to select as payment is pending">
                        <input
                            id={orderId}
                            type="checkbox"
                            checked={isSelected}
                            onClick={onSelect}
                            disabled={!transaction}
                        />
                        </Tippy>
                        :
                        <input
                            id={orderId}
                            type="checkbox"
                            checked={isSelected}
                            onClick={onSelect}
                            disabled={!transaction}
                        />
                    }

                </td>
            } */}
            {/* <td style={{ textAlign: "center" }}>
                {index + 1}
            </td> */}
            <td onClick={(e) => e.stopPropagation()}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "start",
                    justifyContent: "space-between",
                    minWidth: "180px",
                    lineHeight: "1",
                }}>

                    <div>
                        {order?.waybillNo}
                        <Badge
                            className="ml-1"
                            style={{ color: "#fff", fontSize: 12, cursor: "default" }}
                            variant={order?.bulk_id ? "" : "danger"}
                        >
                            {pastDate < currentDate ? "Delayed" : ""}
                        </Badge>

                        {/* {
                            order?.rto_init &&
                            <Badge
                                className="ml-1"
                                style={{ color: "#fff", fontSize: 12 }}
                                variant={"info"}
                            >RTO</Badge>
                        } */}
                    </div>

                    <div className="ml-2" >
                        {
                            order?.waybillNo &&
                            <CopyToClipboard
                                text={order?.waybillNo}
                                onCopy={() => showNotification("info", `Successfully Copy AWB ${order?.waybillNo}`)}
                            >

                                <span className="mr-2">
                                    {/* <Tippy content="Copy AWB">
                                        <i className="far fa-copy" style={{ color: "#495057" }}></i>
                                    </Tippy> */}
                                </span>
                            </CopyToClipboard>
                        }
                    </div>
                </div>
            </td>
            {(roleName === 'administrator' || roleName === 'admin') &&
            <td style={{ maxWidth: "180px", overflow: "hidden", whiteSpace: "nowrap",textOverflow: "ellipsis"}}>
                <Tippy content={order?.userDetails?.[0]?.first_name.charAt(0).toUpperCase() + order?.userDetails?.[0]?.first_name.slice(1)}>
                    <span style={{fontSize: "inherit", color: "inherit"}}>{order?.userDetails?.[0]?.first_name.charAt(0).toUpperCase() + order?.userDetails?.[0]?.first_name.slice(1)}</span>
                </Tippy>
            </td>}
            
            <td style={{ textAlign: "center"}}>
            {order?.deliveryPartner && order?.deliveryPartner.charAt(0).toUpperCase() + order?.deliveryPartner.slice(1)}
            </td>
            <td style={{ letterSpacing: 1 , minWidth: "180px"}}>
                {order?.pickupLocation && order?.pickupLocation.charAt(0).toUpperCase() + order?.pickupLocation.slice(1)}
                {/* {order?.shipDate && moment(order.shipDate).format("D MMM YYYY, h:mm:ss a")} */}
            </td>

            <td
                style={{
                    textTransform:
                        order?.codPaymentMode === "cod"
                            ? "uppercase"
                            : "capitalize",
                    minWidth: "180px",
                   
                }}
            >
                {order?.destinationLocation}
                {/* <span className="ml-1">
                    {order?.action_done ? (
                          <Tippy content="Remitted">
                         <i className="fas fa-check-double" />
                          </Tippy>
                    ) : (
                        order?.sent_to_am &&
                        <Tippy content="Ready to remit">
                        <i className="fas fa-check" />
                        </Tippy>
                    )}
                </span> */}
            </td>

            <td style={{ textTransform: "lowercase" }}>
                 {order?.weight ? Math.ceil(order.weight) : order?.weight}
            </td>
            <td style={{minWidth: "180px"}}>
                {order?.fullStatus}
            </td>
            {/* {
                showTicketStatus &&
                <td style={{ textTransform: "uppercase" }}>
                {order?.shipDate && moment(order.shipDate).format("D MMM YYYY, h:mm:ss a")}
                </td>
            } */}
            <td>
                {
                    order?.shipDate && moment(order.shipDate).format("D MMM YYYY")

                }

            </td>

            <td style={{ minWidth: "105px"}}>{
                order?.fullStatus === "Cancelled" ? "-" : order?.edd ? moment(order.edd).format("D MMM YYYY") : ""
            }</td>

            <td >{
                order?.abdDate && moment(order.abdDate).format("D MMM YYYY")

            }</td>

            <td style={{ textAlign: "center", display: 'flex' }} onClick={(e) => e.stopPropagation()}>
               
                {transaction && (
                    <span
                        className="mr-2"
                        onClick={onShowTicket}
                    >
                        <Tippy content="Track Order" style={{cursor:'pointer'}}>
                            <i class="fa fa-truck" aria-hidden="true"></i>
                        </Tippy>
                    </span>
                )}

                {order?.fullStatus === "Delivered" && order?.deliveryPartner === "gati" && (
                     <a target="_blank"
                        className="mr-2"
                        style={{fontSize: '18px'}}
                        href={order?.pod}>
                        <Tippy content="POD">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        </Tippy>
                    </a>
                )}

                {/* {
                    order?.ewaybill_number &&
                    <CopyToClipboard
                        text={order?.ewaybill_number}
                        onCopy={() => showNotification("info", `Successfully Copy AWB ${order?.ewaybill_number}`)}
                    >
                        <span className="mr-2">
                            <Tippy content="Copy AWB">
                                <i className="far fa-copy"></i>
                            </Tippy>
                        </span>
                    </CopyToClipboard>
                } */}
            </td>
        </tr>
    );
};

const mapStateToProps = (state, ownProps) => {
    let { id } = ownProps;

    let loggedInUser = state?.loggedInUser?.data?.data;

    let printSelection = state.order?.printSelection;
    let orders = state.order?.orders;
    let order = id && orders && orders[id];

    return {
        orderId: id,
        order: order,
        printSelection: printSelection,
        loggedInUser: loggedInUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        trackOrder: (prams) => dispatch(trackOrderRequest(prams)),
        cancelOrder: (prams) => dispatch(cancelOrderRequest(prams)),
        selectOrders: (ids, isSelected) => dispatch(selectOrdersRequest(ids, isSelected)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(B2BOrderItem);
