import React from "react";
import { Route, Redirect } from "react-router-dom";
import CheckUserAuthentication from "../Utils/CheckUserAuthentication";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isOrderActivityRoute = props.location.pathname.includes("/cargo/OrderActivity/"); // making condition to bypass the /cargo/OrderActivity/ 
        if (isOrderActivityRoute || CheckUserAuthentication()) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;