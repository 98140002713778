import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Breadcrumb } from "react-bootstrap";
import CargoTableContainer from "../components/cargoTableContainer";
import { searchAllB2BOrderRequest, searchAllB2BServiceabilityRequest, selectOrdersRequest } from "../../Order/Duck/OrderActions";
import { fetchAllCustomersRequest } from "../../Users/Duck/UsersActions";
import B2BOrderItem from "../components/B2BOrderList"
import { connect } from "react-redux";
import ServiceabilityTableList from "./Components/ServiceabilityTableList";
import ServiceabilityTableContainer from "./Components/ServiceabilityTableContaine";
import moment from "moment";
class Serviceability extends Component {

    constructor(props) {
        let filter = props?.filter;

        super(props);
        this.state = {
            pincode: filter?.pincode || "",

        };

        this.setState(
            {
                fromDate: null,
                toDate: null,
                orderType: "",
                payment_method: "",
                payment_status: "complete",
                logistic_id: "",
                plan: "",
                created_by: "",
                pincode: "",
                mode: "",
                city: "",
                state: "",
                error: null,
            },
            () => {
                this.onFilterOrder();
            });
    }

    uploadServiceablity = (e) => {
        try {
            let { history } = this.props
            e.stopPropagation();
            history?.push(`/cargo/serviceability/upload`);
        }
        catch (e) { }
    }

    handleAWB = (e) => {
        this.setState({ pincode: e?.target?.value });
    };

    handleDP = (e) => {
        this.setState({ mode: e?.target?.value });
    };


    onFilterOrder = () => {
        this.setState({
            error: null,
        });

        this.loadMoreOrders();
    };



    componentDidMount() {
        let { printSelection, selectOrders, fetchAllCustomers, role, permissions } =
            this.props;

        this.loadMoreOrders();
        selectOrders && selectOrders(printSelection, false);
        if (
            (permissions?.showAllOrders || role?.role_id === "vendor") &&
            fetchAllCustomers
        ) {
            fetchAllCustomers();
        }
    }


    loadMoreOrders = (offset = 0, limit = 100) => {
        let {
            fromDate,
            toDate,
            orderType,
            payment_method,
            payment_status,
            logistic_id,
            plan,
            created_by,
            pincode,
            city,
            state,
            mode,
        } = this.state;
        let { fetchAllOrders, orderList, orderMeta, location } = this.props;

        if (offset === 0 || orderMeta?.total_count > orderList?.length) {
            let params = {
                pathname: location?.pathname,
                offset: offset,
                limit: limit,
            };

            if (fromDate)
                params.created_from = moment(fromDate).startOf("day").utc().format();
            if (toDate)
                params.created_to = moment(toDate).endOf("day").utc().format();
            if (orderType && orderType !== "all") params.order_type = orderType;
            if (payment_method && payment_method !== "all")
                params.payment_method = payment_method;
            // if (payment_status && payment_status !== "all")
            params.payment_status = payment_status;
            if (mode && mode !== "all")
                params.mode = mode;
            if (plan) params.plan = plan;
            if (created_by && created_by !== "all") params.created_by = created_by;
            if (pincode) params.pincode = pincode.trim();
            fetchAllOrders && fetchAllOrders(params);
        }
    }


    reset = () => {
        this.setState(
            {
                fromDate: null,
                toDate: null,
                orderType: "",
                payment_method: "",
                payment_status: "complete",
                logistic_id: "",
                plan: "",
                created_by: "",
                pincode: "",
                mode: "",
                city: "",
                state: "",
                error: null,
            },
            () => {
                this.onFilterOrder();
            }
        );
    };

    render() {
        let {
            orderList,
            orderMeta,
            loading,
            logistics,
            plans,
            planOrder,
            filter,
            printSelection,
            selectOrders,
            customers,
            permissions,
        } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">
                                        Serviceability List
                                    </h2>
                                    <Breadcrumb title={["Serviceability", "Serviceability List"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row card">
                    <div className="col-12">
                        <h4 className="card-title mb-0 d-flex justify-content-between">
                            &nbsp;
                            <button type="button" className="btn  btn-primary" style={{ float: 'right' }} onClick={this.uploadServiceablity}>Upload Serviceability</button>

                        </h4>

                        <div className="card-body">
                            <form>
                                <div className="row order_filter">
                                    {
                                        logistics?.length > 0 &&
                                        <div className="col-md-3 col-sm-3 col-12">
                                            <select
                                                className="form-control"
                                                value={this.state.mode}
                                                onChange={this.handleDP}
                                            >
                                                <option value="all">Mode</option>

                                                <option key="1" value="air">
                                                    Air
                                                </option>
                                                <option key="2" value="surface">
                                                    Surface
                                                </option>

                                            </select>
                                        </div>
                                    }

                                    <div className="col-md-3 col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Pincode"
                                            onChange={this.handleAWB}
                                            value={this.state.pincode}
                                            autoComplete="on"
                                        />
                                    </div>

                                    <div className="col-md-2 col-sm-4">
                                        <div className="filter_btns d-flex align-items-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary mr-2 btn-icon-text"
                                                onClick={this.onFilterOrder}
                                                style={{ width: 80 }}
                                            >
                                                Search
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-icon-text"
                                                onClick={this.reset}
                                                style={{ width: 80 }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <ServiceabilityTableContainer
                    headings={[
                        "Mode",
                        "Pincode",
                        "Type",
                        "City",
                        "State",
                        "Zone",
                        "Effective From Date",
                        "Effective To Date",
                        "Pickup",
                        "COD",
                        "ABD"
                    ]}

                    showSelectAll={true}
                    totalEntries={orderMeta?.totalCount || orderMeta?.total_count}
                    rowData={orderList}
                    renderRow={(item, index) => (
                        <ServiceabilityTableList
                            id={item}
                            index={index}
                            showSelect
                            showCancelOrder
                            handleCancelOrderModal={this.handleCancelOrderModal}
                            handleCreateRtoOrderModal={this.handleCreateRtoOrderModal}
                            {...this.props}
                        />
                    )}
                    showSearch={false}
                    loading={loading}
                    filter={filter}
                    loadMore={this.loadMoreOrders}
                    actionBtnText={"Label Preview"}
                    ThermalactionBtnText={"Thermal Label Preview"}
                    showActionBtn={printSelection?.length > 0}
                    onClickAction={this.onShowPrintPreview}
                    onClickDeselectAction={this.onDeselectAll}
                    onSelectAll={selectOrders}
                    selectedList={printSelection}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let { location } = ownProps;
    let pathname = location?.pathname;

    let users = state.users?.users;
    let customers = state.users?.customerList?.map((userId) => ({
        value: userId,
        label: users[userId]?.email,
    }));

    let logistics = state.logistics?.data;

    // let orderState = state.order;
    console.log(state.order?.filters, "state.order?.filters")
    let filter = state.order?.filters[pathname];
    console.log(JSON.stringify(filter), "mBoard")
    let boards = state.order?.boards;
    console.log(boards, "boards")
    let mBoard = boards[JSON.stringify(filter)];
    console.log(mBoard, "mBoard")
    let orderList = mBoard?.list;
    let orderMeta = mBoard?.meta;
    let loading = mBoard?.loading;
    let error = mBoard?.error;

    let printSelection = state.order?.printSelection;

    let loggedInUser = state?.loggedInUser?.data?.data;
    const mVisiblePlans = loggedInUser?.visible_plans || [];
    let role = loggedInUser?.role?._id;
    let permissions = (role?.active && role?.permissions) || {};

    let selected_partners = loggedInUser?.selected_partners || [];
    if (!permissions?.showAllOrders && selected_partners?.length > 0) {
        logistics = logistics?.filter((logistic) =>
            selected_partners.includes(logistic?._id)
        );
    }

    let planOrder = state?.plan?.planOrder;
    if (Array.isArray(planOrder) && mVisiblePlans?.length > 0) {
        planOrder = mVisiblePlans;
    }


    return {
        filter: filter,
        orderList: orderList,
        orderMeta: orderMeta,
        loading: loading,
        error: error,
        logistics: logistics,
        customers: customers,
        printSelection: printSelection,
        role: role,
        permissions: permissions,
        plans: state?.plan?.plans,
        planOrder: planOrder,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllOrders: (prams) => dispatch(searchAllB2BServiceabilityRequest(prams)),
        fetchAllCustomers: (prams) => dispatch(fetchAllCustomersRequest(prams)),
        selectOrders: (ids, isSelected) =>
            dispatch(selectOrdersRequest(ids, isSelected)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Serviceability)