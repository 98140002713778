const ACTIONS = {
    CREATE_REQUEST: "CREATE_ROLE_REQUEST",
    CREATE_SUCCESS: "CREATE_ROLE_SUCCESS",
    CREATE_FAIL: "CREATE_ROLE_FAIL",

    FETCH_All_REQUEST: "FETCH_All_ROLE_REQUEST",
    FETCH_All_SUCCESS: "FETCH_All_ROLE_SUCCESS",
    FETCH_All_FAIL: "FETCH_All_ROLE_FAIL",

    FETCH_REQUEST: "FETCH_ROLE_REQUEST",
    FETCH_SUCCESS: "FETCH_ROLE_SUCCESS",
    FETCH_FAIL: "FETCH_ROLE_FAIL",

    UPDATE_REQUEST: "UPDATE_ROLE_REQUEST",
    UPDATE_SUCCESS: "UPDATE_ROLE_SUCCESS",
    UPDATE_FAIL: "UPDATE_ROLE_FAIL",

    DELETE_REQUEST: "DELETE_ROLE_REQUEST",
    DELETE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_FAIL: "DELETE_ROLE_FAIL",
};

export default ACTIONS;
