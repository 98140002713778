import React from "react";
import { connect } from "react-redux";
import { Bar, Scatter } from "react-chartjs-2";
import moment from "moment";
import { getCurrentMonthOfWeek } from "../../../Utils/CommonFunctions";
import { helpers } from "chart.js";



const CalendarChart = () => {
   // Sample custom data
   const customData = [
    { date: '2025-01-01', value: 50 },
    { date: '2025-02-15', value: 70 },
    { date: '2025-03-10', value: 40 },
    { date: '2025-06-20', value: 80 },
    { date: '2025-12-25', value: 100 },
    // Add more dates and values as needed
  ];

  // Short weekday names (Sun, Mon, Tue, ...)
  const weekdayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Convert custom data to Chart.js-friendly format
  const processYearlyData = (data) => {
    const processedData = data.map((entry) => {
      const date = new Date(entry.date);
      const dayOfWeek = date.getDay(); // 0 = Sunday, 6 = Saturday
      const weekOfYear = Math.ceil((date.getTime() - new Date(date.getFullYear(), 0, 1).getTime()) / (7 * 24 * 60 * 60 * 1000)); // Calculate week number
      return {
        x: dayOfWeek, // Day of the week (0–6)
        y: weekOfYear, // Week of the year
        r: entry.value / 10, // Scale value for circle size
        value: entry.value, // Original value (for tooltip)
        date: entry.date, // Original date
      };
    });
    return processedData;
  };

  const calendarData = processYearlyData(customData);

  // Define data for Chart.js
  const data = {
    datasets: [
      {
        label: 'Calendar ',
        data: calendarData,
        backgroundColor: 'rgba(75, 192, 192, 0.7)',
      },
    ],
  };

  // Define options for Chart.js
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      title: {
        display: true,
        text: 'Yearly Calendar Chart (2025)', // Display year in title
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const { date, value } = context.raw;
            const dayOfWeek = new Date(date).getDay(); // Get day of the week (0–6)
            return `Date: ${date} (${weekdayNamesShort[dayOfWeek]}), Value: ${value}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'category', // Use category scale for x-axis
        labels: weekdayNamesShort, // Use short weekday names (Sun, Mon, ...)
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: 'linear',
        min: 1,
        max: 52, // Total weeks in a year
        ticks: {
          stepSize: 1,
        },
        grid: {
          drawBorder: false,
        },
        title: {
          display: true,
          text: 'Weeks of the Year', // Y-axis label
        },
        afterTickToLabelConversion: function (scale) {
          // Convert ticks to Week names (Week 1, Week 2, etc.)
          scale.ticks = scale.ticks.map((tick) => `Week ${tick}`);
        },
      },
    },
  };

    return (
        <div style={{ width: "100%", height: "100%" }}>

            <Scatter data={data} options={options} />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {};
}


export default connect(mapStateToProps)(CalendarChart);
