const ACTIONS = {
    CREATE_REQUEST: "CREATE_PLAN_REQUEST",
    CREATE_SUCCESS: "CREATE_PLAN_SUCCESS",
    CREATE_FAIL: "CREATE_PLAN_FAIL",

    FETCH_REQUEST: "FETCH_PLAN_REQUEST",
    FETCH_SUCCESS: "FETCH_PLAN_SUCCESS",
    FETCH_FAIL: "FETCH_PLAN_FAIL",

    UPDATE_REQUEST: "UPDATE_PLAN_REQUEST",
    UPDATE_SUCCESS: "UPDATE_PLAN_SUCCESS",
    UPDATE_FAIL: "UPDATE_PLAN_FAIL",

    DELETE_REQUEST: "DELETE_PLAN_REQUEST",
    DELETE_SUCCESS: "DELETE_PLAN_SUCCESS",
    DELETE_FAIL: "DELETE_PLAN_FAIL",
};

export default ACTIONS;
