import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../Utils/BreadCrumb";
import { fetchComments } from "../../Comments/Duck/CommentActions";
import moment from "moment";
import { Redirect } from "react-router-dom";
import MessageModal from "../../Order/Components/MessageModal";
import getB2BTrackingOrders from "../../Order/Duck/OrderSaga"
import appUrl from "../../Constants/AppUrl";
import axios from "axios";
import { showNotification } from "../../Utils/CommonFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AppHeader from "../../AppLayout/AppHeader";
import Tippy from "@tippyjs/react";

const progressSteps = ["Created", "In-Transit", "Taken For Delivery", "Out-For_delivery", "Delivered"];
const progressStepsEkart = ["Created", "In-Transit", "Taken For Delivery", "Out-For_delivery", "Delivered"];
const progressStepsDelivered = ["Created", "In-Transit",  "Taken For Delivery","Out-For_delivery", "DELIVERED"];
class B2BTrackingActivity extends Component {
    constructor(props) {
        super(props); // Always call super(props) in the constructor
        this.state = {
            data: [],
            EkartData: [],
            DeliveredData: [],
            loading: true,
            error: null,
            currentStepIndex: null,
            currentStepIndexEkart: null,
            currentStepIndexDelivered: null,
            searchWaybillNo: "",
            status: null,
            showDeliveredData: false
        };
    }


    handleInputChange = (event) => {
        this.setState({ searchWaybillNo: event.target.value });
    };

    handleTrackOrder = () => {
        const { searchWaybillNo } = this.state;

        if (!searchWaybillNo) {
            alert("Please enter a waybill number.");
            return;
        }

        this.setState({ loading: true, error: null });

        this.props.history.push(`/OrderActivity/${searchWaybillNo}`);

        axios
            .get(`${appUrl.B2B_trackingDetails}?waybillNo=${searchWaybillNo}`)
            .then((response) => {

                if (!this.isValidResponseFormat(response.data)) {
                    showNotification("error", "Invalid response format received.");
                    this.setState({ loading: false });

                }
                if (response.data?.[0]?.trackingDetails?.dktinfo?.[0] === undefined) {
                    const EkartTrakingDetails = response.data[0];
                    const currentStatusEkart = progressStepsEkart.indexOf(EkartTrakingDetails.status);

                    this.setState({
                        EkartData: EkartTrakingDetails,
                        loading: false,
                        currentStepIndexEkart: currentStatusEkart,
                    });
                } else {
                    const transitDetails = response.data?.[0];
                    const currentStatus = progressSteps.indexOf(transitDetails.status);

                    this.setState({
                        data: transitDetails,
                        loading: false,
                        currentStepIndex: currentStatus,
                    });
                }

            })
            .catch((err) => {
                this.setState({ error: err.message, loading: false });
            });
    };

    isValidResponseFormat = (responseData) => {
        if (!Array.isArray(responseData) || responseData.length === 0) return false;

        const item = responseData[0];

        if (
            !item.waybillNo &&
            typeof item.status !== "string" &&
            !item.trackingDetails ||
            typeof item.trackingDetails !== "object" ||
            !item.trackingDetails.requid ||
            !Array.isArray(item.trackingDetails.dktinfo)
        ) {
            return false;
        }
        return true;
    };


    componentDidMount() {

        let { orderId } = this.props.match.params.orderId; // Get the id from props
        console.log(this.props.location.search, "oooo")
        const urlParams = new URLSearchParams(this.props.location.search);
        const status = urlParams.get("status");
        console.log(status, "oooo")

        let baseURL = null;
        let params = null;

        if (status === "Delivered") {
            baseURL = appUrl.B2B_DELIVERY_TRACKING_URL;
            params = `?lrNo=${this.props.match.params.orderId}`;
            this.setState({ status: status, showDeliveredData: true })
        } else {
            baseURL = appUrl.B2B_trackingDetails
            params = `?waybillNo=${this.props.match.params.orderId}`
            this.setState({ status: status, showDeliveredData: false })
        }


        axios
            .get(`${baseURL + params}`)
            .then((response) => {
                console.log(response.data.trackingData, "responseresponse")
                if (response.data.trackingData) {
                    console.log(response.data.trackingData, "hello")
                    const currentDeliveredStatus = progressStepsDelivered.indexOf(response.data.trackingData.data.status);
                    this.setState({ loading: false, DeliveredData: response.data.trackingData, currentStepIndexDelivered: currentDeliveredStatus });

                } else {
                    if (!this.isValidResponseFormat(response.data)) {
                        showNotification("error", "Invalid response format received.");
                        this.setState({ loading: false });
                    }
                    if (response.data?.[0]?.trackingDetails?.dktinfo?.[0] === undefined) {
                        const EkartTrakingDetails = response.data[0];

                        const currentStatusEkart = progressStepsEkart.indexOf(EkartTrakingDetails.status)
                        console.log(EkartTrakingDetails.status, "hello")
                        this.setState({ EkartData: EkartTrakingDetails, loading: false, currentStepIndexEkart: currentStatusEkart });
                    }
                    else {

                        for (let val of response.data?.[0]?.trackingDetails?.dktinfo?.[0]?.TRANSIT_DTLS) {
                            if (val.INTRANSIT_STATUS === "Delivered") {
                                val["POD"] = response.data?.[0]?.trackingDetails?.dktinfo?.[0]?.POD;
                            }

                        }

                        const transitDetails = response.data?.[0]
                        if (response.data?.[0]?.trackingDetails?.dktinfo?.[0] !== undefined) {

                            const currentStatus = progressSteps.indexOf(transitDetails.status)
                            this.setState({ data: transitDetails, loading: false, currentStepIndex: currentStatus });
                            console.log(transitDetails)
                        } else {
                            showNotification("error", response.data?.[0]?.trackingDetails?.dktinfo?.[0]?.errmsg);
                            this.setState({ loading: false });
                        }
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, loading: false });
            });
    }


    render() {
        const { data, EkartData, loading, error, currentStepIndex, currentStepIndexEkart, currentStepIndexDelivered, searchWaybillNo, showDeliveredData, status, DeliveredData } = this.state;
        console.log(DeliveredData.data, "showDeliveredData")
        if (loading) return <p>Loading...</p>;
        // if (error) return <p>Error{showDeliveredData} : {error}</p>;
        if (showDeliveredData) return (
            <>
                <header id="page-topbar">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <div className="d-flex col-lg-3">
                                <div className="navbar-brand-box">
                                    <Link to="/app/dashboard" className="logo logo-dark d-inline-block">
                                        <span className="logo-sm">
                                            <img src="/images/logo2.svg" alt="" height="52" />
                                        </span>
                                        <span className="logo-lg">
                                            <img src="/images/logo1.png" alt="" height="39" />
                                        </span>
                                    </Link>
                                </div>

                            </div>

                            <div className="d-flex col-lg-4 justify-content-end">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button type="button" className="btn header-item noti-icon" id="page-header-search-dropdown"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                                <div className="dropdown d-none d-lg-inline-block ml-1 products_btn" >
                                    <Link type="button" className="btn header-item noti-icon" to="/all/products">
                                        <i className="ti ti-layout-grid4"></i><br /> Products
                                    </Link>

                                </div>
                                <div className="dropdown d-none d-lg-inline-block ml-1 products_btn">

                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">
                                        Track Your Order
                                    </h2>

                                    <BreadCrumb
                                        title={["Shipment", "Track Order"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="col-md-5 ms-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Waybill No."
                                    autoComplete="on"
                                    value={searchWaybillNo}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="col-md-2 ms-1">
                                <button
                                    type="button"
                                    onClick={this.handleTrackOrder}
                                    className="btn btn-primary mr-2 btn-icon-text"
                                    style={{ width: 80 }}
                                >
                                    Search
                                </button>
                            </div>

                        </div>
                        {/* <div className="col-md-3  text-end" >
                            <Link to="/OrderActivity" type="button" target="_blank" className="btn btn-primary theme_bg_color nav-link text-white" >
                                Track with waybill No.
                            </Link>
                        </div> */}
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="timeline">


                                <div style={{ textAlign: 'center', margin: "auto", padding: "20px", fontFamily: "Arial" }}>

                                    <p><strong>Shipment ID:</strong> {DeliveredData.data.lrnum}</p>
                                    <p><strong>Shipment Status: </strong>{DeliveredData.data.status}
                                        {/* {data.trackingDetails.dktinfo[0].POD ? <a class="podLink" href={data.trackingDetails.dktinfo[0].POD} target="_blank">POD</a> : ""} */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="timeline-content">
                    <div className="card">
                        <div className="container">
                            {/* Progress Tracker */}
                            <div style={{ paddingTop: '15px' }}>
                                <h2>Shipment Progress</h2>
                                <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
                                    {progressSteps.map((step, index) => (
                                        <div key={index} style={{ textAlign: "center" }}>
                                            <div
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    borderRadius: "50%",
                                                    backgroundColor: index <= currentStepIndexDelivered ? "#4caf50" : "#ccc",
                                                    margin: "0 auto",
                                                    lineHeight: "30px",
                                                    color: "#fff",
                                                }}
                                            >
                                                {index + 1}
                                            </div>
                                            <p style={{ marginTop: "5px", fontSize: "14px" }}>{step}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <hr />
                            <br />
                            <div style={{ marginBottom: "20px" }}>
                                <h2>Tracking Details</h2><br />
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>WBN No.</th>
                                            <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>DATE</th>
                                            {/* <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>STATUS</th> */}
                                            <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>STATUS</th>
                                            <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>LOCATION</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {DeliveredData.data.wbns.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.wbn}{" "}</td>
                                                <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.delivered_date}{" "}</td>
                                                {/* <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>
                                                                        {item.INTRANSIT_STATUS}
                                                                    </td> */}
                                                <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.status}{" "}</td>
                                                <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.location}{" "}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        );

        if (!showDeliveredData)
            return (
                <>
                    <header id="page-topbar">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <div className="d-flex col-lg-3">
                                    <div className="navbar-brand-box">
                                        <Link to="/app/dashboard" className="logo logo-dark d-inline-block">
                                            <span className="logo-sm">
                                                <img src="/images/logo2.svg" alt="" height="52" />
                                            </span>
                                            <span className="logo-lg">
                                                <img src="/images/logo1.png" alt="" height="39" />
                                            </span>
                                        </Link>
                                    </div>

                                </div>





                                <div className="d-flex col-lg-4 justify-content-end">
                                    <div className="dropdown d-inline-block d-lg-none ml-2">
                                        <button type="button" className="btn header-item noti-icon" id="page-header-search-dropdown"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">
                                            <form className="p-3">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                    <div className="dropdown d-none d-lg-inline-block ml-1 products_btn" >
                                        <Link type="button" className="btn header-item noti-icon" to="/all/products">
                                            <i className="ti ti-layout-grid4"></i><br /> Products
                                        </Link>

                                    </div>
                                    <div className="dropdown d-none d-lg-inline-block ml-1 products_btn">
                                        {/* <Link to="/login" type="button" className="btn-primary theme_bg_color nav-link text-white" >
                                        Log In
                                    </Link> */}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </header>



                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-header">
                                    <div>
                                        <h2 className="main-content-title tx-24 mg-b-5">
                                            Track Your Order
                                        </h2>

                                        <BreadCrumb
                                            title={["Shipment", "Track Order"]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-md-6 d-flex justify-content-end">
                                <div className="col-md-5 ms-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Waybill No."
                                        autoComplete="on"
                                        value={searchWaybillNo}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="col-md-2 ms-1">
                                    <button
                                        type="button"
                                        onClick={this.handleTrackOrder}
                                        className="btn btn-primary mr-2 btn-icon-text"
                                        style={{ width: 80 }}
                                    >
                                        Search
                                    </button>
                                </div>

                            </div>
                            {/* <div className="col-md-3  text-end" >
                            <Link to="/OrderActivity" type="button" target="_blank" className="btn btn-primary theme_bg_color nav-link text-white" >
                                Track with waybill No.
                            </Link>
                        </div> */}
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                {(data.trackingDetails !== undefined) ? (
                                    <div className="timeline">


                                        <div style={{ textAlign: 'center', margin: "auto", padding: "20px", fontFamily: "Arial" }}>

                                            <p><strong>Shipment ID:</strong> {data.waybillNo}</p>
                                            <p><strong>Shipment Status: </strong>{data.status}{data.trackingDetails.dktinfo[0].POD ? <a class="podLink" href={data.trackingDetails.dktinfo[0].POD} target="_blank">POD</a> : ""}</p>
                                        </div>


                                        <div className="timeline-content">
                                            <div className="card">
                                                <div className="container">
                                                    {/* Progress Tracker */}
                                                    <div style={{ paddingTop: '15px' }}>
                                                        <h2>Shipment Progress</h2>
                                                        <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
                                                            {progressSteps.map((step, index) => (
                                                                <div key={index} style={{ textAlign: "center" }}>
                                                                    <div
                                                                        style={{
                                                                            width: "30px",
                                                                            height: "30px",
                                                                            borderRadius: "50%",
                                                                            backgroundColor: index <= currentStepIndex ? "#4caf50" : "#ccc",
                                                                            margin: "0 auto",
                                                                            lineHeight: "30px",
                                                                            color: "#fff",
                                                                        }}
                                                                    >
                                                                        {index + 1}
                                                                    </div>
                                                                    <p style={{ marginTop: "5px", fontSize: "14px" }}>{step}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <br />
                                                    <div style={{ marginBottom: "20px" }}>
                                                        <h2>Tracking Details</h2><br />
                                                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>DATE</th>
                                                                    <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>TIME</th>
                                                                    {/* <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>STATUS</th> */}
                                                                    <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>STATUS</th>
                                                                    <th style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>LOCATION</th>

                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {data.trackingDetails.dktinfo[0].TRANSIT_DTLS.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.INTRANSIT_DATE}{" "}</td>
                                                                        <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.INTRANSIT_TIME}{" "}</td>
                                                                        {/* <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>
                                                                        {item.INTRANSIT_STATUS}
                                                                    </td> */}
                                                                        <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.INTRANSIT_STATUS_CODE}{" "}</td>
                                                                        <td style={{ textAlign: "left", borderBottom: "1px solid #ccc", border: "1px solid #ccc", padding: "7px" }}>{item.INTRANSIT_LOCATION}{" "}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>




                                    </div>
                                ) : (
                                    <div className="timeline-content">
                                        <div className="card">
                                            <div className="container">
                                                <div style={{ textAlign: 'center', margin: "auto", padding: "20px", fontFamily: "Arial" }}>

                                                    <p><strong>Shipment ID:</strong> {EkartData.waybillNo}</p>
                                                    <p><strong>Shipment Status:</strong> {EkartData.status}</p>
                                                </div>

                                                {/* Progress Tracker */}
                                                <div style={{ paddingTop: '15px' }}>
                                                    <h2>Shipment Progress</h2>
                                                    <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
                                                        {progressStepsEkart.map((step, index) => (
                                                            <div key={index} style={{ textAlign: "center" }}>
                                                                <div
                                                                    style={{
                                                                        width: "30px",
                                                                        height: "30px",
                                                                        borderRadius: "50%",
                                                                        backgroundColor: index <= currentStepIndexEkart ? "#4caf50" : "#ccc",
                                                                        margin: "0 auto",
                                                                        lineHeight: "30px",
                                                                        color: "#fff",
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </div>
                                                                <p style={{ marginTop: "5px", fontSize: "14px" }}>{step}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <hr />
                                                <br />
                                                <div style={{ marginBottom: "20px" }}>
                                                    <h2>Tracking Details</h2><br />
                                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>DATE</th>
                                                                {/* <th style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>STATUS</th> */}
                                                                <th style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>STATUS</th>
                                                                <th style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", borderTop: "1px solid #ccc", paddingBottom: "5px", padding: "7px" }}>REMARK</th>

                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {EkartData?.trackingDetails?.processTimeLineLogsList?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", padding: "7px" }}>{item.statusTime}{" "}</td>
                                                                    {/* <td style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", padding: "7px" }}>{item.status}{" "}</td> */}
                                                                    <td style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", padding: "7px" }}>{item.statusCode}{" "}</td>
                                                                    <td style={{ textAlign: "left", border: "1px solid #ccc", borderRight: "1px solid #ccc", padding: "7px" }}>{item.remarks}{" "}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}

                            </div>

                        </div>
                    </div>


                </>
            );
    }
}


export default B2BTrackingActivity;
