const ACTIONS = {
    FETCH_ALL_REMITTANCE_REQUEST: "FETCH_ALL_REMITTANCE_REQUEST",
    FETCH_ALL_REMITTANCE_SUCCESS: "FETCH_ALL_REMITTANCE_SUCCESS",
    FETCH_ALL_REMITTANCE_FAIL: "FETCH_ALL_REMITTANCE_FAIL",

    UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
    UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",

    UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
    UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
    UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",

    FETCH_ACCOUNTS_REQUEST: "FETCH_ACCOUNTS_REQUEST",
    FETCH_ACCOUNTS_SUCCESS: "FETCH_ACCOUNTS_SUCCESS",
    FETCH_ACCOUNTS_FAIL: "FETCH_ACCOUNTS_FAIL",

    VERIFY_ACCOUNTS_REQUEST: "VERIFY_ACCOUNTS_REQUEST",
    VERIFY_ACCOUNTS_SUCCESS: "VERIFY_ACCOUNTS_SUCCESS",
    VERIFY_ACCOUNTS_FAIL: "VERIFY_ACCOUNTS_FAIL",

    UPDATE_ACCOUNTS_REQUEST: "UPDATE_ACCOUNTS_REQUEST",
    UPDATE_ACCOUNTS_SUCCESS: "UPDATE_ACCOUNTS_SUCCESS",
    UPDATE_ACCOUNTS_FAIL: "UPDATE_ACCOUNTS_FAIL",
};

export default ACTIONS;
