

import React, { Component } from 'react'
import moment from 'moment';
import 'tippy.js/dist/tippy.css';


import jwtDecode from 'jwt-decode'
import { Redirect } from 'react-router-dom'

import ScrollToTop from '../ScrollToTop';
import AppHeader from '../AppLayout/AppHeader';
import AppSidebar from '../cargoLayout/cargoSidenave';
import CargoContent from '../cargoLayout/cargoContent';
import AppFooter from '../AppLayout/AppFooter';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { showNotification } from '../Utils/CommonFunctions';


class LandingPageContent extends Component {

    state = {
        redirectToLogin: false,
        showSideBar: true,
        showChangePassword: false,
    }


    loadMoreOrders = () => {
        showNotification("info", "Please reach out to your Account Manager to activate this service or write to us at hello@yolojet.com!");

    }

    componentDidUpdate(prevProps) {
        const { fetchLogistics, loggedInUser } = this.props
        if (!prevProps?.loggedInUser) {
            if (loggedInUser && !loggedInUser?.user_type?._id) {
                fetchLogistics?.();
            }
        }
    }

    logOut = () => {
        this.setState({ redirectToLogin: true })
        localStorage.clear();
        this.props.logOut();
    }

    toggleSideBar = () => {
        this.setState({ showSideBar: !this.state.showSideBar })
    }



    closeSideBar = () => {
        let windowWidth = window.innerWidth
        if (windowWidth <= 991) {
            this.setState({ showSideBar: false })
        }
    }

    handleChangePasswordModal = (show = false) => {
        show = typeof show === "boolean" && show;
        this.setState({
            showChangePassword: show,
        });
    }

    


    render() {
        const { loggedInUser, orderMeta } = this.props
        const { redirectToLogin, showSideBar, showChangePassword } = this.state

        if (redirectToLogin) {
            return <Redirect to='/login' />
        }

        return (
            <div id='layout-wrapper'>
                <ScrollToTop>
                   

        <div className={this.props.showSideBar ? "" : "main-content"}>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <Link to="/app/dashboard" className="col-xl-4">
                            <div className="card flex-row align-items-center common_card ttl_amt">
                                <div class="landing_card_img">
                                <img src="/images/rupee.png"></img>
                                </div>
                                <div>
                                    <h5 className="mb-0">D2C Domestic Shipping </h5><br/>
                                    <p className="mb-0 disabled_text"> Enhance your customer experience with cutting-edge D2C shipping 
                                                    solutions for seamless website and channel order fulfillment</p>
                                </div>
                            </div>
                        </Link>

                        <Link to="/cargo/cargo_dash" className="col-xl-4">
                            <div className="card flex-row align-items-center common_card indivisual_orders">
                                <div class="landing_card_img">
                                <img src="/images/indivisual.png"></img>
                                </div>
                                <div>
                                    <h5 className="mb-0">B2B and MPS Bulk shipping </h5><br/>
                                    <p className="mb-0 disabled_text">Streamline appointment-based and non-appointment bulk deliveries across India with ease.</p>
                                </div>
                            </div>
                        </Link>

                        <Link onClick={this.loadMoreOrders} className="col-xl-4">
                       
                            <div className="card flex-row align-items-center common_card bulk_orders">
                                <div class="landing_card_img">
                                <img src="/images/bulk.png"></img>
                                </div>
                                <div>
                                    <h5 className="mb-0">Warehousing and Fulfillment</h5><br/>
                                    <p className="mb-0 disabled_text">Optimize your supply chain with tailored warehousing solutions and efficient inventory management.</p>
                                </div>
                            </div>
                           
                        </Link>

                        </div>
                        <div className="row">
                        <div className='col-xl-2'></div>

                        <Link onClick={this.loadMoreOrders} className="col-xl-4">
                       
                        <div className="card flex-row align-items-center common_card pending_orders">
                            <div class="landing_card_img">
                            <img src="/images/bulk.png"></img>
                            </div>
                            <div>
                                <h5 className="mb-0">International Ocean and Air Freight </h5><br/>
                                <p className="mb-0 disabled_text">Expand your global reach with reliable international shipping solutions that boost brand visibility.</p>
                            </div>
                        </div>
                      
                        </Link>

                        <Link onClick={this.loadMoreOrders} className="col-xl-4">
                       
                        <div className="card flex-row align-items-center common_card pending_orders">
                            <div class="landing_card_img">
                            <img src="/images/bulk.png"></img>
                            </div>
                            <div>
                                <h5 className="mb-0">Freight Forwarding and Custom Clearance/brokerage </h5><br/>
                                <p className="mb-0 disabled_text">Navigate global trade complexities effortlessly with expert customs management and brokerage services.</p>
                            </div>
                        </div>
                        
                        </Link>
                        <div className='col-xl-2'></div>
                    </div>
                </div>
            </div>
        </div>
                    <AppFooter showSideBar={showSideBar} />
                </ScrollToTop>

              
            </div>
        )
    }
}

export default LandingPageContent



// import { Link } from "react-router-dom/cjs/react-router-dom.min"


// const LandingPageContent = (props) => {
//     return (
//         <div className={props.showSideBar ? "" : "main-content"}>
//             <div className="page-content">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Link to="/app/dashboard" className="col-xl-4">
//                             <div className="card flex-row align-items-center common_card ttl_amt">
//                                 <div class="landing_card_img">
//                                 <img src="/images/rupee.png"></img>
//                                 </div>
//                                 <div>
//                                     <h5 className="mb-0">D2C</h5>
//                                     <p className="mb-0 disabled_text">Lorem Ipsum has been the industry's </p>
//                                 </div>
//                             </div>
//                         </Link>

//                         <Link to="/cargo/home_page" className="col-xl-4">
//                             <div className="card flex-row align-items-center common_card indivisual_orders">
//                                 <div class="landing_card_img">
//                                 <img src="/images/indivisual.png"></img>
//                                 </div>
//                                 <div>
//                                     <h5 className="mb-0">Cargo</h5>
//                                     <p className="mb-0 disabled_text">Lorem Ipsum has been the industry's </p>
//                                 </div>
//                             </div>
//                         </Link>

//                         <Link to="/app/dashboard" className="col-xl-4">
                       
//                             <div className="card flex-row align-items-center common_card bulk_orders">
//                                 <div class="landing_card_img">
//                                 <img src="/images/bulk.png"></img>
//                                 </div>
//                                 <div>
//                                     <h5 className="mb-0">D2B</h5>
//                                     <p className="mb-0 disabled_text">Lorem Ipsum has been the industry's </p>
//                                 </div>
//                             </div>
                           
//                         </Link>

//                         <Link to="/app/dashboard" className="col-xl-4">
                       
//                        <div className="card flex-row align-items-center common_card pending_orders">
//                            <div class="landing_card_img">
//                            <img src="/images/bulk.png"></img>
//                            </div>
//                            <div>
//                                <h5 className="mb-0">B2B</h5>
//                                <p className="mb-0 disabled_text">Lorem Ipsum has been the industry's </p>
//                            </div>
//                        </div>
                      
//                    </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default LandingPageContent