import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { getCurrentMonthOfWeek } from "../../../Utils/CommonFunctions";
import { format } from "path";
import appUrl from "../../../Constants/AppUrl";
import axios from "axios";


// Chart options
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: (context) => `ABD Shipments: ${context.raw}`, // Display "Sales" on tooltip
      },
    },
  },

  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,       // Keep the x-axis grid line options
          drawOnChartArea: false, // Remove the vertical grid lines inside the chart
          drawTicks: true,     // Keep tick marks
          drawBorder: true,    // Ensure the x-axis line is visible
          color: '#ccc',    // Customize the axis line color
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,       // Keep the y-axis grid line options
          drawOnChartArea: false, // Remove the horizontal grid lines inside the chart
          drawTicks: true,     // Keep tick marks
          drawBorder: true,    // Ensure the y-axis line is visible
          color: '#ccc',    // Customize the axis line color
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },

}

class B2BBarChar extends Component {

  constructor(props) {
    super(props); // Always call super(props) in the constructor
    this.state = {
      data: {},
      NoData: false,
    };
  }

  formatDateToDDMMYY = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year

    return `${day}/${month}/${year}`;
  }


  componentDidMount() {
    axios
      .get(`${appUrl.B2B_DASHBOARD_ABDCHART_URL}`)
      .then((response) => {

        const labelsData = []

        for (let i = 0; i < response.data.data.labels.length; i++) {
          labelsData.push(this.formatDateToDDMMYY(response.data.data.labels[i]))
        }

        const labels = labelsData;
        const dataset = response.data.data.datasets[0].data;
        console.log(response.data)
        const ABDData = {

          labels: labels, // Dates for the next 10 days
          datasets: [
            {
              label: 'ABD Shipments', // Dataset label
              data: dataset, // Custom values for each day
              backgroundColor: 'rgba(75, 192, 192, 0.5)', // Bar color
              borderColor: 'rgba(75, 192, 192, 1)', // Border color
              borderWidth: 1,
            },
          ],

        };
        console.log(ABDData, "ABDData")

        if (ABDData.datasets[0].data.length===0) {
          this.setState({ data: ABDData, NoData: true })

        } else {
          this.setState({ data: ABDData, NoData: false })
        }
      })
      .catch((err) => {
        this.setState({ error: err.message, loading: false, NoData: true });
      });
  }


  render() {
    const { data, NoData } = this.state;
    if (NoData) return <p className="p-10" style={{ textAlign: "center" }}>No Data Available</p>;

    return (
      <div style={{ width: "70%", height: "80%", minHeight: "60%" , display:"flex"}}>
        <Bar data={data} options={options} />
      </div>
    );

  };

  // const mapStateToProps = (state, ownProps) => {
  //   return {};
  // }
}


export default B2BBarChar;
