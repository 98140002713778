import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Modal,Button,Breadcrumb } from "react-bootstrap";
import CargoTableContainer from "../components/cargoTableContainer";
import { searchAllB2BOrderRequest, searchAllB2BABDRequest, selectOrdersRequest } from "../../Order/Duck/OrderActions";
import { fetchAllCustomersRequest } from "../../Users/Duck/UsersActions";
import B2BOrderItem from "../components/B2BOrderList"
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import AppointmentTableList from "./Components/AppointmentTableList";
import AppointmentTableContainer from "./Components/AppointmentTableContainer";
import moment from "moment";
import appUrl from "../../Constants/AppUrl";
import axios from "axios";
import * as XLSX from "xlsx";
import UploaderAppointment from "./uploader_appointment";

class Appointment extends Component {

    constructor(props) {
        let filter = props?.filter;

        super(props);
        this.state = {
            showUploadModal: false,
            client: filter?.client || "",
            awbNo: filter?.awbNo || "",
            fromDate:
                (filter?.created_from && moment(filter?.created_from).toDate()) || null,
            // toDate:
            //         (filter?.created_to && moment(filter?.created_to).toDate()) || null,
            appointmentDate: (filter?.created_from && moment(filter?.created_from).toDate()) || null,
            poNumber: filter?.poNumber || "",
        };

        this.setState(
            {
                fromDate: null,
                toDate: null,
                client: "",
                awbNo: "",
                poNumber: "",
                shipmentStatus: "",
                appointmentDate: null,
                channel: "",
                courierpartner:"",
                error: null,
                isExporting: false
            },
            () => {
                this.onFilterOrder();
            });
    }

    uploadABD = (e) => {
        try {
            // let { history } = this.props
            e.stopPropagation();
            // history?.push(`/cargo/appointment/ABDupload`);
            this.setState({ showUploadModal: true });
        }
        catch (e) { }
    }
    handleClose = () => {
        this.setState({ showUploadModal: false });
    };

    handleAWB = (e) => {
        this.setState({ awbNo: e?.target?.value });
    };

    handlePoNumber = (e) => {
        this.setState({ poNumber: e?.target?.value });
    };

    handleClient = (e) => {
        this.setState({ client: e?.target?.value });
    };

    handleInputChange = (field) => (e) => {
        this.setState({ [field]: e.target.value });
    };


    onFilterOrder = () => {
        this.setState({
            error: null,
        });

        this.loadMoreOrders();
    };

    selectDateFrom = (date) => {
        if (
            this.state.toDate !== null &&
            moment(date).isAfter(moment(this.state.toDate))
        ) {
            // alert('From date should not be greater than to date')
            this.setState({ error: "From date should not be greater than to date" });
            return false;
        }
        console.log(date, "hhhh")
        this.setState({
            fromDate: date,
            error: null,
        });
    };

    selectDateTo = (date) => {
        if (this.state.fromDate !== null && moment(date).isBefore(moment(this.state.fromDate))) {
            // alert('To date should not be less than from date')
            this.setState({ error: 'To date should not be less than from date' })
            return false
        }
        this.setState({
            toDate: date,
            error: null
        });
    }

    selectAppointmentDate = (date) => {
        this.setState({ appointmentDate: date });
    }

    componentDidMount() {
        let { printSelection, selectOrders, fetchAllCustomers, role, permissions } =
            this.props;

        this.loadMoreOrders();
        selectOrders && selectOrders(printSelection, false);
        if (
            (permissions?.showAllOrders || role?.role_id === "vendor") &&
            fetchAllCustomers
        ) {
            fetchAllCustomers();
        }
    }






    loadMoreOrders = (offset = 0, limit = 100) => {
        let {
            fromDate,
            toDate,
            appointmentDate,
            shipmentStatus,
            client,
            awbNo,
            poNumber,
            channel,
            courierpartner,
        } = this.state;
        let { fetchAllOrders, orderList, orderMeta, location } = this.props;

        if (offset === 0 || orderMeta?.total_count > orderList?.length) {
            let params = {
                pathname: location?.pathname,
                offset: offset,
                limit: limit,
            };
            if (appointmentDate) {
                //params.appointmentDate = moment(appointmentDate).startOf('day').utc().format();
                params.appointmentDate = moment(appointmentDate).format('YYYY-MM-DD');
            }
            if (poNumber) params.poNumber = poNumber;
            if (client) params.clientName = client.trim();
            if (shipmentStatus && shipmentStatus !== "all") params.shipmentStatus = shipmentStatus;
            if (awbNo) params.awbNo = awbNo;
            if (channel && channel !== "all") params.channelPartner = channel.trim();
            if (courierpartner && courierpartner !== "all") params.courierpartner = courierpartner.trim();

            fetchAllOrders && fetchAllOrders(params);
        }
    }

    ExportABD = (e) => {
        try {
            this.setState({
                isExporting: true,
            });

            let { role } = this.props;
            axios
                .get(`${appUrl.B2B_ABD_DOWNLOAD_URL}?download_all=true`)
                .then((response) => {
                    console.log(response.data.data, "res")
                    const result = response.data.data;
                    if (role === "Client") {
                       const customData = result.map((item) => ({
                            "AWB Number": item.awbNo, // Rename "name" to "Full Name"
                            "Courier partener": item.carrier,
                            "Shipment Status": item.status,
                            "Appointment Date": item.appointmentDate,
                            "PO/Invoice/ASM Number": item.poNumber,
                            "Channel": item.channelPartner,
                        }));
                        // Convert data to a worksheet
                        const worksheet = XLSX.utils.json_to_sheet(customData);

                        // Auto-adjust column width based on data length
                        const columnWidths = Object.keys(customData[0]).map((key) => {
                            const maxLength = Math.max(
                                key.length, // Header length
                                ...customData.map((row) => row[key]?.toString().length || 0) // Data length
                            );
                            return { wch: maxLength + 2 }; // Add some padding
                        });

                        worksheet["!cols"] = columnWidths; // Set column widths

                        // Create a workbook and append the worksheet
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, "ABD Data");

                        // Export the Excel file
                        XLSX.writeFile(workbook, "ExportABDList.xlsx");
                        this.setState({
                            isExporting: false,
                        });
                    } else {
                        const customData = result.map((item) => ({
                            "AWB Number": item.awbNo, // Rename "name" to "Full Name"
                            "Client Name": item.clientName,
                            "Shipment Status": item.status,
                            "Appointment Date": item.appointmentDate,
                            "PO/Invoice/ASM Number": item.poNumber,
                            "Channel": item.channelPartner,
                            "Courier Partner": item.carrier,
                        }));

                        // Convert data to a worksheet
                        const worksheet = XLSX.utils.json_to_sheet(customData);

                        // Auto-adjust column width based on data length
                        const columnWidths = Object.keys(customData[0]).map((key) => {
                            const maxLength = Math.max(
                                key.length, // Header length
                                ...customData.map((row) => row[key]?.toString().length || 0) // Data length
                            );
                            return { wch: maxLength + 2 }; // Add some padding
                        });

                        worksheet["!cols"] = columnWidths; // Set column widths

                        // Create a workbook and append the worksheet
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, "ABD Data");

                        // Export the Excel file
                        XLSX.writeFile(workbook, "ExportABDList.xlsx");
                        this.setState({
                            isExporting: false,
                        });
                    }


                })
                .catch((err) => {
                    this.setState({ error: err.message, loading: false });
                });

        }
        catch (e) { }
    }


    reset = () => {
        this.setState(
            {
                fromDate: null,
                toDate: null,
                client: "",
                awbNo: "",
                poNumber: "",
                shipmentStatus: "",
                appointmentDate: null,
                channel: "",
                courierpartner:"",
                error: null,
                isExporting: false
            },
            () => {
                this.onFilterOrder();
            }
        );
    };

    render() {
        let {
            orderList,
            orderMeta,
            loading,
            logistics,
            plans,
            planOrder,
            filter,
            printSelection,
            selectOrders,
            customers,
            permissions,
            role,
            isExporting
        } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">
                                        Appointment Base Delivery List
                                    </h2>
                                    <Breadcrumb title={["ABD", "Appointment Base Deliver List"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row card">
                    <div className="col-12">
                        <h4 className="card-title mb-0 d-flex justify-content-between">
                            &nbsp;
                            <span style={{ float: 'right' }}>
                               
                                <button type="button" className="btn  btn-primary" onClick={this.uploadABD}>Upload ABD</button>
                                &nbsp;
                                <button type="button" className="btn  btn-primary" style={{ float: 'right' }} onClick={this.ExportABD} disabled={isExporting}>

                                    {
                                        isExporting ?
                                            <>
                                                <span className="spinner-border spinner-border-sm"></span>
                                                <span className="visually-hidden">  Exporting...</span>
                                            </>
                                            : "Export ABD"
                                    }
                                </button>
                            </span>
                        </h4>
                        
                        {(role === 'Administrator' || role === 'Admin') &&
                            <div className="card-body">
                            <form>
                                <div className="row order_filter mb-3">
                                    {/* {
                                        logistics?.length > 0 &&
                                        <div className="col-md-3 col-sm-3 col-12">
                                            <select
                                                className="form-control"
                                                value={this.state.mode}
                                                onChange={this.handleDP}
                                            >
                                                <option value="all">Mode</option> 

                                                <option key="1" value="air">
                                                    AWB No.
                                                </option>
                                                <option key="2" value="surface">
                                                    awbNo 
                                                </option>

                                            </select>
                                        </div>
                                    } */}

                                    <div className="col-md-3 col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Client Name "
                                            onChange={this.handleClient}
                                            value={this.state.client}
                                            autoComplete="on"
                                        />
                                    </div>                                    
                                    
                                    <div className="col-md-3 col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="AWB No."
                                            onChange={this.handleAWB}
                                            value={this.state.awbNo}
                                            autoComplete="on"
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="PO/Invoice/ASM Number"
                                            onChange={this.handlePoNumber}
                                            value={this.state.poNumber}
                                            autoComplete="on"
                                        />
                                    </div>
                                </div>
                                <div className="row order_filter mb-3">
                                    {/* <div className="col-md-3">
                                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                                        <DatePicker
                                            selected={this.state.fromDate}
                                            onChange={this.selectDateFrom}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="From"
                                            className="form-control"
                                            selectsStart
                                            startDate={this.selectDateFrom}
                                            endDate={this.state.toDate}
                                            isClearable
                                            style={{ flex: 1 }}
                                        />{!this.state.fromDate && (
                                            <span style={{ marginLeft: '4px',fontSize: '18px',}}
                                            >
                                                <i className="bx bx-calendar-alt"></i>
                                            </span>
                                        )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <DatePicker
                                            selected={this.state.toDate}
                                            onChange={this.selectDateTo}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="To"
                                            className="form-control"
                                            selectsEnd
                                            startDate={this.state.fromDate}
                                            endDate={this.state.toDate}
                                            minDate={this.state.fromDate}
                                            isClearable
                                            style={{ flex: 1 }}
                                        />
                                        {!this.state.fromDate && (
                                            <span style={{ marginLeft: '4px',fontSize: '18px',}}
                                            >
                                                <i className="bx bx-calendar-alt"></i>
                                            </span>
                                        )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DatePicker
                                                selected={this.state.appointmentDate}
                                                onChange={this.selectAppointmentDate}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                placeholderText="Appointment Date"
                                                //maxDate={new Date()}
                                                isClearable
                                                style={{ flex: 1 }}
                                            />
                                            {!this.state.appointmentDate && (
                                                <span style={{ marginLeft: '4px', fontSize: '18px', }}>
                                                    <i className="bx bx-calendar-alt"></i>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3 ml-2">
                                        <div className="filter_btns d-flex align-items-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary mr-2 btn-icon-text"
                                                onClick={this.onFilterOrder}
                                                style={{ width: 80 }}
                                            >
                                                Search
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-icon-text"
                                                onClick={this.reset}
                                                style={{ width: 80 }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>                        
                        }
                        {(role === 'Client') &&
                            <div className="card-body">
                            <form>
                                <div className="row order_filter mb-3">
               
                                    <div className="col-md-3 col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="AWB No."
                                            onChange={this.handleAWB}
                                            value={this.state.awbNo}
                                            autoComplete="on"
                                        />
                                    </div>

                                    <div className="col-md-3 col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="PO/Invoice/ASM Number"
                                            onChange={this.handlePoNumber}
                                            value={this.state.poNumber}
                                            autoComplete="on"
                                        />
                                    </div>

                                    <div className="col-md-3 ml-2">
                                        <div className="filter_btns d-flex align-items-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary mr-2 btn-icon-text"
                                                onClick={this.onFilterOrder}
                                                style={{ width: 80 }}
                                            >
                                                Search
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-icon-text"
                                                onClick={this.reset}
                                                style={{ width: 80 }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row order_filter mb-3">
                                     <div className="col-md-3">
                                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                                        <DatePicker
                                            selected={this.state.fromDate}
                                            onChange={this.selectDateFrom}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="From"
                                            className="form-control"
                                            selectsStart
                                            startDate={this.selectDateFrom}
                                            endDate={this.state.toDate}
                                            isClearable
                                            style={{ flex: 1 }}
                                        />{!this.state.fromDate && (
                                            <span style={{ marginLeft: '4px',fontSize: '18px',}}
                                            >
                                                <i className="bx bx-calendar-alt"></i>
                                            </span>
                                        )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <DatePicker
                                            selected={this.state.toDate}
                                            onChange={this.selectDateTo}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="To"
                                            className="form-control"
                                            selectsEnd
                                            startDate={this.state.fromDate}
                                            endDate={this.state.toDate}
                                            minDate={this.state.fromDate}
                                            isClearable
                                            style={{ flex: 1 }}
                                        />
                                        {!this.state.fromDate && (
                                            <span style={{ marginLeft: '4px',fontSize: '18px',}}
                                            >
                                                <i className="bx bx-calendar-alt"></i>
                                            </span>
                                        )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DatePicker
                                                selected={this.state.appointmentDate}
                                                onChange={this.selectAppointmentDate}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                placeholderText="Appointment Date"
                                                //maxDate={new Date()}
                                                isClearable
                                                style={{ flex: 1 }}
                                            />
                                            {!this.state.appointmentDate && (
                                                <span style={{ marginLeft: '4px', fontSize: '18px', }}>
                                                    <i className="bx bx-calendar-alt"></i>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3 ml-2">
                                        <div className="filter_btns d-flex align-items-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary mr-2 btn-icon-text"
                                                onClick={this.onFilterOrder}
                                                style={{ width: 80 }}
                                            >
                                                Search
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary btn-icon-text"
                                                onClick={this.reset}
                                                style={{ width: 80 }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </form>

                        </div> 
                        }
                        

                    </div>
                </div>

                {
                    (role === 'Administrator' || role === 'Admin') &&

                    <>
                        <AppointmentTableContainer
                            headings={[
                                "Client Name",
                                "AWB No.",
                                "Shipment Status",
                                "Appointment Date",
                                "PO/Invoice/ASM Number",
                                "Channel",
                                "Courier Partner",
                                "Action"
                            ]}

                            showSelectAll={true}
                            totalEntries={orderMeta?.totalCount || orderMeta?.total_count}
                            rowData={orderList}
                            renderRow={(item, index) => (
                                <AppointmentTableList
                                    id={item}
                                    index={index}
                                    showSelect
                                    showCancelOrder
                                    handleCancelOrderModal={this.handleCancelOrderModal}
                                    handleCreateRtoOrderModal={this.handleCreateRtoOrderModal}
                                    {...this.props}
                                />
                            )}
                            showSearch={false}
                            loading={loading}
                            filter={filter}
                            loadMore={this.loadMoreOrders}
                            actionBtnText={"Label Preview"}
                            ThermalactionBtnText={"Thermal Label Preview"}
                            showActionBtn={printSelection?.length > 0}
                            onClickAction={this.onShowPrintPreview}
                            onClickDeselectAction={this.onDeselectAll}
                            onSelectAll={selectOrders}
                            selectedList={printSelection}
                        />
                    </>
                }

                {
                    (role === 'Client') &&
                    <>
                        <AppointmentTableContainer
                            headings={[
                                "AWB No.",
                                "Courier partener",
                                "Shipment Status",
                                "Appointment Date",
                                "PO/Invoice/ASM Number",
                                "Channel",
                                "Action"
                            ]}

                            showSelectAll={true}
                            totalEntries={orderMeta?.totalCount || orderMeta?.total_count}
                            rowData={orderList}
                            renderRow={(item, index) => (
                                <AppointmentTableList
                                    id={item}
                                    index={index}
                                    showSelect
                                    showCancelOrder
                                    handleCancelOrderModal={this.handleCancelOrderModal}
                                    handleCreateRtoOrderModal={this.handleCreateRtoOrderModal}
                                    {...this.props}
                                />
                            )}
                            showSearch={false}
                            loading={loading}
                            filter={filter}
                            loadMore={this.loadMoreOrders}
                            actionBtnText={"Label Preview"}
                            ThermalactionBtnText={"Thermal Label Preview"}
                            showActionBtn={printSelection?.length > 0}
                            onClickAction={this.onShowPrintPreview}
                            onClickDeselectAction={this.onDeselectAll}
                            onSelectAll={selectOrders}
                            selectedList={printSelection}
                        />
                    </>
                }
                <Modal show={this.state.showUploadModal} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <UploaderAppointment />  
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let { location } = ownProps;
    let pathname = location?.pathname;

    let users = state.users?.users;
    let customers = state.users?.customerList?.map((userId) => ({
        value: userId,
        label: users[userId]?.email,
    }));

    let logistics = state.logistics?.data;

    // let orderState = state.order;
    console.log(state.order?.filters, "state.order?.filters")
    let filter = state.order?.filters[pathname];
    console.log(JSON.stringify(filter), "mBoard")
    let boards = state.order?.boards;
    console.log(boards, "boards")
    let mBoard = boards[JSON.stringify(filter)];
    console.log(mBoard, "mBoard")
    let orderList = mBoard?.list;
    let orderMeta = mBoard?.meta;
    let loading = mBoard?.loading;
    let error = mBoard?.error;

    let printSelection = state.order?.printSelection;


    let loggedInUser = state?.loggedInUser?.data?.data;
    const mVisiblePlans = loggedInUser?.visible_plans || [];
    let role = loggedInUser?.role?._id?.name;
    let permissions = (role?.active && role?.permissions) || {};

    let selected_partners = loggedInUser?.selected_partners || [];
    if (!permissions?.showAllOrders && selected_partners?.length > 0) {
        logistics = logistics?.filter((logistic) =>
            selected_partners.includes(logistic?._id)
        );
    }

    let planOrder = state?.plan?.planOrder;
    if (Array.isArray(planOrder) && mVisiblePlans?.length > 0) {
        planOrder = mVisiblePlans;
    }


    return {
        filter: filter,
        orderList: orderList,
        orderMeta: orderMeta,
        loading: loading,
        error: error,
        logistics: logistics,
        customers: customers,
        printSelection: printSelection,
        role: role,
        permissions: permissions,
        plans: state?.plan?.plans,
        planOrder: planOrder,
        loggedInUser: loggedInUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllOrders: (prams) => dispatch(searchAllB2BABDRequest(prams)),
        fetchAllCustomers: (prams) => dispatch(fetchAllCustomersRequest(prams)),
        selectOrders: (ids, isSelected) =>
            dispatch(selectOrdersRequest(ids, isSelected)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Appointment)