import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { getCurrentMonthOfWeek } from "../../../Utils/CommonFunctions";
import { format } from "path";
import appUrl from "../../../Constants/AppUrl";
import axios from "axios";

// Helper function to generate an array of dates for the current month (1 to 31)
const generateDateLabels = () => {
  const currentDate = new Date();
  const labels = [];

  for (let i = 0; i < 10; i++) {
    const date = new Date();
    date.setDate(currentDate.getDate() + i); // Get the date for the next i days
    labels.push(date.toLocaleDateString()); // Add formatted date to labels array
  }

  return labels;
};



// Chart options
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: (context) => `Value: ${context.raw}`, // Show value in tooltip
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,       // Keep the x-axis grid line options
          drawOnChartArea: false, // Remove the vertical grid lines inside the chart
          drawTicks: true,     // Keep tick marks
          drawBorder: true,    // Ensure the x-axis line is visible
          color: '#ccc',    // Customize the axis line color
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,       // Keep the y-axis grid line options
          drawOnChartArea: false, // Remove the horizontal grid lines inside the chart
          drawTicks: true,     // Keep tick marks
          drawBorder: true,    // Ensure the y-axis line is visible
          color: '#ccc',    // Customize the axis line color
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

class B2BMultiStackedChart extends Component {
  constructor(props) {
    super(props); // Always call super(props) in the constructor
    this.state = {
      data: {},
      NoData: false,
    };
  }

  formatDateToDDMMYY = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year

    return `${day}/${month}/${year}`;
  }

  transformData(originalData) {
    const result = {};
    const colors = [
      "rgba(255, 99, 132, 0.7)", // Red
      "rgba(54, 162, 235, 0.7)", // Blue
      "rgba(255, 159, 64, 0.7)", // Yellow
      "rgba(75, 192, 192, 0.7)", // Green
      "rgba(153, 102, 255, 0.6)", // Purple
      "rgba(255, 159, 64, 0.6)", // Orange
  ];

    // Initialize the result object with empty arrays for each status
    originalData.data.forEach(entry => {
      entry.statuses.forEach(status => {
        if (!result[status.status]) {
          result[status.status] = [];
        }
      });
    });

    // Populate the result object with the transformed data
    originalData.data.forEach(entry => {
      entry.statuses.forEach(status => {
        result[status.status].push({
          x: entry.date,
          y: status.count
        });
      });
    });

    const formattedResult = Object.keys(result).map((status, index) => ({
      label: status,
      data: result[status],
      backgroundColor: colors[index % colors.length], // Assign a color from the array, looping if necessary
      borderWidth: 1,
       stack: `group${Math.floor(index / 2) + 1}`,
  }));
    
    return formattedResult;
  }
  

  componentDidMount() {

    axios
      .get(`${appUrl.B2B_SHIPMENT_PERFORMANCE_URL}`)
      .then((response) => {
        
        const FormatedData = this.transformData(response.data)
        const labelsData = []

        for (let i = 0; i < FormatedData.length; i++) {
          // 
          var listOfDataset = FormatedData[i].data
          listOfDataset.map((element, index, array) => { 
            console.log(element.x, "hello")
            labelsData.push(this.formatDateToDDMMYY(element.x))
          });
        }
        const last10Data = labelsData.slice(-10);
       
        const SPData = {
          labels: last10Data, // Dates for the next 10 days
          datasets: FormatedData

        };
        console.log(SPData, "ABDData")

        if (SPData.datasets[0].data.length===0) {
          this.setState({ data: SPData, NoData: true })

        } else {
          this.setState({ data: SPData, NoData: false })
        }

      })
      .catch((err) => {
        this.setState({ error: err.message, loading: false, NoData: true });
      });
  }



  render() {
    const { data, NoData } = this.state;
    if (NoData) return <p className="p-10" style={{ textAlign: "center" }}>No Data Available</p>;

    return (
      <div style={{ width: "90%", height: "90%" }}>
        <Bar data={data} options={options} />
      </div>
    );
  };
}



export default B2BMultiStackedChart
