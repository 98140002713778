const ACTIONS = {
    FETCH_ALL_CSV: "FETCH_ALL_CSV",
    FETCH_ALL_CSV_SUCCESS: "FETCH_ALL_CSV_SUCCESS",
    FETCH_ALL_CSV_FAIL: "FETCH_ALL_CSV_FAIL",

    UPLOAD_FILE: "UPLOAD_FILE",
    UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",
};

export default ACTIONS;
