

import React from 'react'

const BridgeContent = () => {
        return (
            <>
                <section className="pt-5 pb-5 bridge_content_main_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-md-12">
                                    <h5 className="heading_fs">Call us at 8882780086 or drop some ❤️ at hello@yolojet.com to</h5>
                                    <div className="row">
                                        <div className="col-md-9 col-sm-12">
                                            <h4 className="mt-4 f21"><i class="fa fa-check" aria-hidden="true"></i> Kiss your shipping headaches goodbye!</h4>
                                            <h4 className="mt-4 f21"><i class="fa fa-check" aria-hidden="true"></i> Partner with us for a seamless journey to UNICORN status</h4>
                                            <h4 className="mt-4 f21"><i class="fa fa-check" aria-hidden="true"></i> Take the guesswork out of appointment and modern-trade deliveries</h4>
                                            <h4 className="mt-4 f21"><i class="fa fa-check" aria-hidden="true"></i> Optimize your marketing spends</h4>
                                            <h4 className="mt-4 f21"><i class="fa fa-check" aria-hidden="true"></i> Experience a shipping solution that truly delivers</h4>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12 text-white">
                                            <p>
                                                <img src="img/bridge-content.png" width="250px" alt=""/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>    
                </section>    
            </>
        )
    }

export default BridgeContent
