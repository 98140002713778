import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Badge, Breadcrumb } from 'react-bootstrap';
import FilePreviewWrapper from '../../FilePreview/filePreviewWrapper';
import { Tab, Tabs } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import { getCurrentMonthOfWeek } from '../../Utils/CommonFunctions';
import FilePreviewModal from '../../FilePreview/filePreviewModal';
import CounterContainer from '../../Dashboard/Components/CounterContainer';
import { fetchDashboardRequest } from '../../Dashboard/Duck/DashboardActions';
import DashboardChart from '../../Dashboard/Components/DashboardChart';
import BreadCrumb from '../../Utils/BreadCrumb';
import BaseTable from "../../Utils/BaseTable";
import B2BMultistackedChart from './Components/B2BMultistackedChart';
import CalendarChart from './Components/CalenderChart';
import B2BPieChart from './Components/CalenderChart';
import PieChart from './Components/pieChart';
import B2BBarChart from './Components/B2BBarChart';
import axios from 'axios';
import appUrl from '../../Constants/AppUrl';
class CargoDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPreviewOfFileId: null,
            fromDate: null,
            toDate: null,
            OrdersData: {}
        }

        this.selectDateFrom = this.selectDateFrom.bind(this);
        this.selectDateTo = this.selectDateTo.bind(this);
    }

    selectDateFrom = (date) => {
        if (this.state.toDate !== null && moment(date).isAfter(moment(this.state.toDate))) {
            // alert('From date should not be greater than to date')
            this.setState({ error: 'From date should not be greater than to date' })
            return false
        }
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        this.setState({
            fromDate: `${day}/${month}/${year}`,
            error: null
        });
    }
    selectDateTo = (date) => {
        if (this.state.fromDate !== null && moment(date).isBefore(moment(this.state.fromDate))) {
            // alert('To date should not be less than from date')
            this.setState({ error: 'To date should not be less than from date' })
            return false
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        this.setState({
            toDate: `${day}/${month}/${year}`,
            error: null
        });
    }
    handleChange = (name) => (e) => {
        let value = e?.target?.value;

        this.setState({
            [name]: value
        });
    }


    componentDidMount() {
        // let { fetchDashboard } = this.props;
        // fetchDashboard && fetchDashboard();
        this.loadDashBoard();
        this.getDashboardData()
    }

    getDashboardData = () => {
        axios
            .get(`${appUrl.B2B_DASHBOARD_SUMMARY_URL}`)
            .then((response) => {
                console.log(response.data.data)
                const data ={"totalOrders":1010,"manifested":590,"inTransit":780,"delivered":40, "OFD": 300}
                this.setState({ OrdersData:  response.data.data })
            })
            .catch((err) => {
                this.setState({ error: err.message, loading: false });
            });
    }


    loadDashBoard = () => {
        let { fromDate, toDate, orderType, payment_method, logistic_id, plan } = this.state;
        let { fetchDashboard, location } = this.props;

        let params = {
            pathname: location?.pathname,
        };

        if (fromDate) params.created_from = moment(fromDate).startOf('day').utc().format();
        if (toDate) params.created_to = moment(toDate).endOf('day').utc().format();
        if (orderType && orderType !== "all") params.order_type = orderType;
        if (payment_method && payment_method !== "all") params.payment_method = payment_method;
        if (logistic_id && logistic_id !== "all") params.logistic_id = logistic_id;
        if (plan && plan !== "all") params.plan = plan;

        fetchDashboard && fetchDashboard(params);
    }



    renderRow = (order, index) => {
        let transaction = order?.transaction_id;
        let orderStatus = Array.isArray(order?.orderStatus) && order.orderStatus.length > 0 && order.orderStatus[0];
        let scanDetail = Array.isArray(orderStatus?.scan_detail) && orderStatus?.scan_detail?.length > 0 && orderStatus.scan_detail[orderStatus.scan_detail.length - 1];
        let comments = order?.comments || [];
        let mLogistic;

        let orderRowBg;
        if (scanDetail?.status_description == "UNDELIVERED") {
            orderRowBg = "#f8d7da";
        } else if (comments?.length > 0) {
            orderRowBg = "#fff3cd";
        }

        if ((transaction || order?.ewaybill_number) && Array.isArray(order?.available_logistic) && order?.available_logistic?.length > 0) {
            mLogistic = order?.available_logistic[0];
        }

        return (
            order && (
                <tr
                    key={index}
                    // className={!order?.bulk_id ? "pointer" : ""}
                    style={{ background: orderRowBg }}
                // onClick={(!order?.bulk_id) ? (() => this.onShowOrderSummary(order?._id)) : undefined}
                >
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td>{order?.ewaybill_number} <Badge className='ml-1' style={{ color: '#fff', fontSize: 12 }} variant={(order?.bulk_id) ? 'warning' : 'primary'}> {(order?.bulk_id) ? 'Bulk' : 'Individual'}</Badge></td>
                    <td style={{ letterSpacing: 1 }} >{order?.order_date && moment(order.order_date).format("D MMM YYYY, h:mm:ss a")}</td>
                    <td style={{ textTransform: order?.payment_method === 'cod' ? 'uppercase' : 'capitalize' }}>
                        {order?.payment_method} <span className="ml-1">{order?.action_done ? <i className="fas fa-check-double" /> : order?.sent_to_am && <i className="fas fa-check" />}</span>
                    </td>

                    <td>{mLogistic?.price?.day ? `${mLogistic?.price?.day} days` : "-"}</td>
                    <td style={{ textTransform: 'lowercase' }} className={order?.ewaybill_number || order?.isLoading ? 'greenColor' : 'redColor'}>
                        {
                            order?.isLoading
                                ? "Checking..."
                                : order?.ewaybill_number
                                    ? (order?.latest_order_status || scanDetail?.status)
                                    : 'payment pending'
                        }
                    </td>
                    
                </tr>
            )
        );
    };

    onFilter = () => {
        this.setState({
            error: null,
        }, this.loadDashBoard);
    }

    onReset = () => {
        this.setState({
            fromDate: null,
            toDate: null,
            orderType: '',
            payment_method: '',
            logistic_id: '',
            plan: '',
            awbNumber: '',
            error: null
        }, this.loadDashBoard);
    }

    render() {
        let {OrdersData} = this.state;
        let { data, loading, logistics, plans, planOrder, delivered_order_weeks, confirm_order_weeks, last_four_weeks_deliver_orders } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between pb-1">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24">
                                        Dashboard
                                    </h2>
                                    <BreadCrumb
                                        title={[
                                            "Dashboard",
                                            "Dashboard",
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-start ">

                    <div className="col-md-4 pr-0">
                        <div className="dashboard_four common_grid_css  br-5 mb-3" style={{ background: '#A888B5', color: '#fff', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:"5px 25px"  }}>
                            <div className="d-flex justify-content-between">
                                <p className="font-size-14">Total Orders</p>
                                {/* <span className="theme_text_color font-size-18"><i class="fa fa-download ml-2" aria-hidden="true"></i></span> */}
                            </div>
                            <h4 className="text-black font-size-24" style={{ color: '#fff' }}>{OrdersData.totalOrders}</h4>
                        </div>
                    </div>

                    <div className="col-md-2 pr-0" style={{ color: 'rgba(255, 99, 132, 0.7)', fontWeight: "bold" }}>
                        <div className="dashboard_four common_grid_css  br-5 mb-3" style={{ border: ' 1px solid rgba(255, 99, 132, 0.7)', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:"5px 25px"  }}>
                            <div className="d-flex justify-content-between">
                                <p className="font-size-14">Manifested</p>
                                {/* <span className="theme_text_color font-size-18"><i class="fa fa-download ml-2" aria-hidden="true"></i></span> */}
                            </div>
                            <h4 className="text-black font-size-24" style={{ color: 'rgba(255, 99, 132, 0.7)' }}>{OrdersData.created}</h4>
                        </div>
                    </div>

                    <div className="col-md-2 pr-0" style={{ color: 'rgba(54, 162, 235, 0.7)', fontWeight: "bold" }}>
                        <div className="dashboard_five common_grid_css   br-5 mb-3" style={{ border: ' 1px solid rgba(54, 162, 235, 0.7)', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:"5px 25px" }}>
                            <div className="d-flex justify-content-between">
                                <p className="font-size-14">In-Transits</p>
                                {/* <span className="theme_text_color font-size-18"><i class="fa fa-download ml-2" aria-hidden="true"></i></span> */}
                            </div>
                            <h4 className="text-black font-size-24" style={{ color: 'rgba(54, 162, 235, 0.7)' }}>{OrdersData.inTransit}</h4>
                        </div>
                    </div>
                    

                    <div className="col-md-2 pr-0" style={{ color: 'rgba(255, 159, 64, 0.7)', fontWeight: "bold" }}>
                        <div className="dashboard_four common_grid_css  br-5 mb-3" style={{ border: ' 1px solid rgba(255, 159, 64, 0.7)', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:"5px 25px"  }}>
                            <div className="d-flex justify-content-between">
                                <p className="font-size-14">OFD &nbsp;</p>
                            </div>
                            <h4 className="text-black font-size-24" style={{ color: 'rgba(255, 159, 64, 0.7)' }}>{OrdersData.outForDeliveryCount}</h4>
                        </div>
                    </div>
                    <div className="col-md-2 pr-0" style={{ color: 'rgba(75, 192, 192, 0.7)', fontWeight: "bold" }}>
                        <div className="dashboard_four common_grid_css  br-5 mb-3" style={{ border: ' 1px solid rgba(53, 132, 132, 0.7)', boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding:"5px 25px"  }}>
                            <div className="d-flex justify-content-between">
                                <p className="font-size-14">Delivered</p>
                                {/* <span className="theme_text_color font-size-18"><i class="fa fa-download ml-2" aria-hidden="true"></i></span> */}
                            </div>
                            <h4 className="text-black font-size-24" style={{ color: 'rgba(75, 192, 192, 0.7)' }}>{OrdersData.delivered}</h4>
                        </div>
                    </div>


                </div>
                <br></br>


                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                        <div className="d-flex card-title">
                            <h5 className="">
                                    Shipment Performance
                                </h5>
                                
                            </div>

                            <div
                                className="border-0 "
                                style={{  width: "100%", /* Full width */
                                    maxWidth: "500px", /* Optional: Limit max width */
                                    height: "300px", /* Fixed height */
                                    margin: "auto", /* Center the card */
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    padding: "10px"}} 
                            >
                                <B2BMultistackedChart
                                   
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="card">
                            
                        <div className="d-flex card-title">
                                <h5 className="">
                                    Load Against Shipping Partner
                                </h5>
                            </div>

                            <div
                                className="border-0 "
                                style={{  width: "100%", /* Full width */
                                    maxWidth: "500px", /* Optional: Limit max width */
                                    height: "300px", /* Fixed height */
                                    margin: "auto", /* Center the card */
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    aspectRatio: "1 / 1", /* Keep the chart square */
                                    padding: "10px"}}
                            >
                                <PieChart />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mb-2">
                        <div className="card">
                            <div className="d-flex card-title">
                            <h5 className="">
                                    ABD
                                </h5>
                            </div>
                            <div
                                className="border-0 m-4 justify-content-center"
                                style={{display: "flex"}}
                            >
                                <B2BBarChart
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { location } = ownProps;
    let pathname = location?.pathname;

    // let dashboard = state.dashboard;
    // let dashboardData = dashboard?.data;
    // let loading = dashboard?.loading;

    let filter = state.dashboard?.filters[pathname];
    let boards = state.dashboard?.boards;

    let mBoard = boards[JSON.stringify(filter || {})];
    let dashboardData = mBoard?.data;
    let loading = !dashboardData && mBoard?.loading;

    let logistics = state.logistics?.data;

    // let total_week = moment().endOf("month").week() - moment().startOf("month").week() + 1;
    let total_week = getCurrentMonthOfWeek();
    let confirm_order_weeks = [... new Array(total_week)].map((_, i) => (dashboardData?.confirm_order_weeks?.count?.[i + 1] || 0));

    // let month_start_week = moment().startOf("month").week();
    let delivered_order_weeks = [... new Array(total_week)].map((_, i) => (dashboardData?.delivered_order_weeks?.count?.[i + 1] || 0));

    // let last_four_week_start = moment().subtract(4, "week").week() + 1;
    let weeks_order_invoice = dashboardData?.last_four_weeks_deliver_orders?.count || {};
    let last_four_weeks_deliver_orders = [... new Array(4)].map((_, i) =>
        (weeks_order_invoice?.[i + 1] ? parseFloat(weeks_order_invoice?.[i + 1] || 0).toFixed(2) : 0)
    );

    return {
        data: dashboardData,
        loading: loading,
        logistics: logistics,
        plans: state?.plan?.plans,
        planOrder: state?.plan?.planOrder,
        confirm_order_weeks: confirm_order_weeks,
        delivered_order_weeks: delivered_order_weeks,
        last_four_weeks_deliver_orders: last_four_weeks_deliver_orders
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDashboard: (params) => dispatch(fetchDashboardRequest(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoDashboard);
