const ACTIONS = {
    FETCH_ALL_REPORTS_REQUEST: "FETCH_ALL_REPORTS_REQUEST",
    FETCH_ALL_REPORTS_SUCCESS: "FETCH_ALL_REPORTS_SUCCESS",
    FETCH_ALL_REPORTS_FAIL: "FETCH_ALL_REPORTS_FAIL",

    FETCH_ONE_REPORT_REQUEST: "FETCH_ONE_REPORT_REQUEST",
    FETCH_ONE_REPORT_SUCCESS: "FETCH_ONE_REPORT_SUCCESS",
    FETCH_ONE_REPORT_FAIL: "FETCH_ONE_REPORT_FAIL",

    REMIT_REPORT_ORDERS_REQUEST: "REMIT_REPORT_ORDERS_REQUEST",
    REMIT_REPORT_ORDERS_SUCCESS: "REMIT_REPORT_ORDERS_SUCCESS",
    REMIT_REPORT_ORDERS_FAIL: "REMIT_REPORT_ORDERS_FAIL",
};

export default ACTIONS;
