const ACTIONS = {
    FETCH_WALLET_REQUEST: "FETCH_WALLET_REQUEST",
    FETCH_WALLET_SUCCESS: "FETCH_WALLET_SUCCESS",
    FETCH_WALLET_FAIL: "FETCH_WALLET_FAIL",

    FETCH_USER_WALLET_REQUEST: "FETCH_USER_WALLET_REQUEST",
    FETCH_USER_WALLET_SUCCESS: "FETCH_USER_WALLET_SUCCESS",
    FETCH_USER_WALLET_FAIL: "FETCH_USER_WALLET_FAIL",

    FETCH_ALL_USERS_BANK_DETAIL_REQUEST: "FETCH_ALL_USERS_BANK_DETAIL_REQUEST",
    FETCH_ALL_USERS_BANK_DETAIL_SUCCESS: "FETCH_ALL_USERS_BANK_DETAIL_SUCCESS",
    FETCH_ALL_USERS_BANK_DETAIL_FAIL: "FETCH_ALL_USERS_BANK_DETAIL_FAIL",

    VERIFY_BANK_DETAIL_REQUEST: "VERIFY_BANK_DETAIL_REQUEST",
    VERIFY_BANK_DETAIL_SUCCESS: "VERIFY_BANK_DETAIL_SUCCESS",
    VERIFY_BANK_DETAIL_FAIL: "VERIFY_BANK_DETAIL_FAIL",
};

export default ACTIONS;
