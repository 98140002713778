import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Breadcrumb } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import { showNotification } from "../../Utils/CommonFunctions";
import axios from "axios";
import appUrl from "../../Constants/AppUrl";
import * as XLSX from "xlsx";

let errorFile = [];
let errorSampleData = [];
class UploaderServiceability extends Component {

    constructor(props) {
        let filter = props?.filter;

        super(props);
        this.state = {
            file: null,
            isUploading: false,
            isShowModel: true,
            resMeta: null,
            attachment: null,
            errorFile: null,
            fromDate:
                (filter?.created_from && moment(filter?.created_from).toDate()) || null,
            toDate:
                (filter?.created_to && moment(filter?.created_to).toDate()) || null,
        };
        this.selectDateFrom = this.selectDateFrom.bind(this);
        this.selectDateTo = this.selectDateTo.bind(this);

    }

    formatDateToDDMMYY = (dateString) => {
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year

        return `${day}/${month}/${year}`;
    }



    selectDateFrom = (date) => {
        if (
            this.state.toDate !== null &&
            moment(date).isAfter(moment(this.state.toDate))
        ) {
            // alert('From date should not be greater than to date')
            this.setState({ error: "From date should not be greater than to date" });
            return false;
        }

        console.log(date, "hhhh")
        this.setState({
            fromDate: date,
            error: null,
        });
    };

    selectDateTo = (date) => {
        console.log(date, "dateeee")
        if (this.state.fromDate !== null && moment(date).isBefore(moment(this.state.fromDate))) {
            // alert('To date should not be less than from date')
            this.setState({ error: 'To date should not be less than from date' })
            return false
        }


        this.setState({
            toDate: date,
            error: null
        });
    }


    handleChange = () => (e) => {
        let valueDP = e?.target?.value;
        this.setState({
            deliveryPartner: valueDP

        });
    }

    handleChangeMode = () => (e) => {
        let valueMode = e?.target?.value;
        this.setState({
            mode: valueMode
        });
    }



    componentDidMount() { }

    valiDateUploadFields = () => {
        let { file, mode, deliveryPartner, fromDate, toDate } = this.state;
        let isValid = false;
        if (deliveryPartner === undefined) {
            showNotification("warning", "Please Select Delivery Partner!");
            return isValid = false;

        } else if (mode === undefined) {
            showNotification("warning", "Please Select Mode!");
            return isValid = false;
        } else if (fromDate === null) {
            isValid = false;
            showNotification("warning", "Please Select Effective From Date!");

            // } else if (toDate === null) {
            //     showNotification("warning", "Please Select Effective To Date!");
            //     return isValid = false;
        } else if (!file) {
            showNotification("warning", "Please Choose a File!");
            return isValid = false;
        } else {
            return isValid = true;
        }
    }

    fileUplaodEvent = (e) => {
        let file = e?.target?.files[0]
        if (file) {
            let extension = file.name?.split('.')?.[file.name.split('.').length - 1]
            if (extension === 'xls' || extension === 'xlsx') {
                this.setState({ file: e?.target?.files[0] })
            } else {
                showNotification("error", "Upload xls or xlsx format file only!");
            }
        }
    }


    uploadFile = (e) => {
        let { file, mode, deliveryPartner, fromDate, toDate } = this.state;
        let isValid = this.valiDateUploadFields();
        if (isValid) {
            this.setState({
                isUploading: true,
            });

            let formData = new FormData();
            console.log(toDate, "toDate")
            formData.append("file", file);
            let reqBody
            if (toDate == null) {
                reqBody = {
                    "mode": mode,
                    "deliveryPartner": deliveryPartner,
                    "effectiveFrom": this.formatDateToDDMMYY(fromDate),
                    "effectiveTo": ""
                }
            } else {
                reqBody = {
                    "mode": mode,
                    "deliveryPartner": deliveryPartner,
                    "effectiveFrom": this.formatDateToDDMMYY(fromDate),
                    "effectiveTo": this.formatDateToDDMMYY(toDate)
                }
            }



                formData.append('data', JSON.stringify(reqBody));

                console.log(formData, "mode")


                axios.post(`${appUrl.UPLOAD_CARGO_URL}/service_ability_uploader`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                }).then(res => {
                    let resData = res?.data;
                    let resMeta = resData?.meta;
                    this.setState({
                        resMeta: resMeta,
                        isShowModel: true,
                        isUploading: false,
                        file: null,
                        errorFile: null,
                        mode: null,
                        deliveryPartner: null,
                        toDate: null,
                        fromDate: null

                    });

                    if (resData.errorData.length > 0) {

                        showNotification("error", resData.errorData.length + " row has errors and " + resData.insertedData.length + " row is uploaded!");
                        errorFile = resData.errorData;
                        errorFile.map((event, index) => {
                            event.row["message"] = event.errors[0]
                            errorSampleData.push(event.row)
                        })
                        this.setState({
                            isShowModel: true,
                            isUploading: false,
                            file: null,
                            errorFile: null,
                            errorFile: errorSampleData,
                            file: null,
                            resMeta: null,
                            mode: "",
                            deliveryPartner: "",
                            toDate: null,
                            fromDate: null

                        });

                    } else {
                        showNotification("success", resData.insertedData.length + " row is uploaded Successfully!");
                    }


                    // this.getMasterDataFile();
                })

                    .catch(err => {
                        this.setState({
                            isUploading: false,
                        });

                    })
            }


        }
        

        // Function to export JSON to Excel
        exportToExcel = () => {

            // Ensure the "message" column is the first column
            const reorderedData = errorSampleData.map((row) => ({
                message: row.message,
                ...Object.fromEntries(
                    Object.entries(row).filter(([key]) => key !== "message") 
                ),
            }));
            
            // Create a new workbook
            const workbook = XLSX.utils.book_new();
    
            // Convert JSON data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(reorderedData);

            // Auto-adjust column width based on the content length
            const columnWidths = Object.keys(reorderedData[0]).map((key) => ({
                wch: Math.max(
                    key.length, 
                    ...reorderedData.map((row) => row[key]?.toString().length || 10) 
                ) + 2 
            }));

            // Apply column widths to the worksheet
            worksheet['!cols'] = columnWidths;

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Export the workbook as an Excel file
            XLSX.writeFile(workbook, "ErrorServiceabilityFile.xlsx");

            this.setState({
                file: null,
                errorFile: null,
                isUploading: false,
                isShowModel: true,
                resMeta: null,
                mode: "",
                deliveryPartner: "",
                toDate: null,
                fromDate: null
            })

        };



        resetSelection = () => {
            this.setState({
                file: null,
                errorFile: null,
                isUploading: false,
                isShowModel: true,
                resMeta: null,
                mode: "",
                deliveryPartner: "",
                toDate: null,
                fromDate: null
            })
        }


        render() {
            let { loggedInUser } = this.props
            const { file, mode, deliveryPartner, isUploading, attachment, errorFile } = this.state;

            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-header">
                                    <div>
                                        <h2 className="main-content-title tx-24 mg-b-5">
                                            Upload Serviceability
                                        </h2>
                                        <Breadcrumb title={["Serviceability", "Upload Serviceability"]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row card">
                        <div className="col-12">
                            {errorFile &&
                                < h4 className="card-title mb-0 d-flex justify-content-between" >

                                    {/* <a href={"/error_data.xlsx"}> */}
                                    <a onClick={this.exportToExcel}>
                                        <span className={"btn-link_err"} style={{ fontSize: 14 }}>
                                            Download Error File
                                            <i
                                                className="fa fa-download ml-2"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </a>
                                </h4>
                            }

                            <h4 className="card-title mb-0 d-flex justify-content-between">
                                &nbsp;
                                <a href={"/serviceUploaderSample.xlsx"}>
                                    <span className={"btn-link"} style={{ fontSize: 14 }}>
                                        Sample File
                                        <i
                                            className="fa fa-download ml-2"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </a>
                            </h4>

                            <div className="card-body">
                                <div className="container">
                                    <div class="row">
                                        <div className="col-md-3 col-sm-3 col-12"><span style={{ fontSize: '16px', fontWeight: '500' }}>Delivery Partners:</span></div>
                                        <div className="col-md-7 col-sm-7 col-12">
                                            <select
                                                className="form-control"
                                                value={this.state.deliveryPartner}
                                                onChange={this.handleChange()}
                                            >
                                                <option value="all">All Delivery Partners</option>
                                                <option value="Ekart">Ekart</option>
                                                <option value="gati">Gati</option>
                                            </select>
                                        </div>
                                    </div><br />

                                    <div class="row">
                                        <div className="col-md-3 col-sm-3 col-12"><span style={{ fontSize: '16px', fontWeight: '500' }}>Mode:</span></div>
                                        <div className="col-md-7 col-sm-7 col-12">
                                            <select
                                                className="form-control"
                                                value={this.state.mode}
                                                onChange={this.handleChangeMode()}
                                            >
                                                <option value="all">All Modes</option>
                                                <option value="air">Air</option>
                                                <option value="surface">Surface</option>
                                            </select>
                                        </div>
                                    </div><br />


                                    <div class="row">
                                        <div className="col-md-3 col-sm-3 col-12"><span style={{ fontSize: '16px', fontWeight: '500' }}>Effective From Date:</span></div>
                                        <div className="col-md-9 col-sm-9 col-12">
                                            <label>
                                                <DatePicker
                                                    selected={this.state.fromDate}
                                                    onChange={this.selectDateFrom}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="From"
                                                    isClearable
                                                />
                                                {!this.state.fromDate && (
                                                    <span>
                                                        <i className="bx bx-calendar-alt"></i>
                                                    </span>
                                                )}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div className="col-md-3 col-sm-3 col-12"><span style={{ fontSize: '16px', fontWeight: '500' }}>Effective To Date:</span></div>
                                        <div className="col-md-9 col-sm-9 col-12">
                                            <label>
                                                <DatePicker
                                                    selected={this.state.toDate}
                                                    onChange={this.selectDateTo}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="To"
                                                    isClearable
                                                />
                                                {!this.state.toDate && (
                                                    <span>
                                                        <i className="bx bx-calendar-alt"></i>
                                                    </span>
                                                )}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div className="col-md-3 col-sm-3 col-12"><span style={{ fontSize: '16px', fontWeight: '500' }}>Upload Zone Excel:</span></div>
                                        <div className="col-md-9 col-sm-9 col-12">
                                            <form
                                                className="needs-validation"
                                                noValidate
                                            >
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="upload_img">
                                                            <div className="file_upload">
                                                                <input
                                                                    type="file"
                                                                    className="fileupload"
                                                                    name="fileupload"
                                                                    accept=".xls, .xlsx"
                                                                    // multiple
                                                                    onChange={this.fileUplaodEvent}
                                                                />
                                                            </div>
                                                            <div className="upload_banner">
                                                                <span>
                                                                    <i
                                                                        className="fa fa-upload"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </span>
                                                                <p>Choose file</p>
                                                            </div>
                                                            {
                                                                file &&
                                                                <div className='mt-3'>
                                                                    <span>
                                                                        <i className="fa fa-file-excel" style={{ fontSize: 18 }} aria-hidden="true"></i>
                                                                        <span style={{ fontSize: 16, marginLeft: 8 }}>{file.name}</span>
                                                                    </span>
                                                                </div>
                                                            }

                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-12 text-right my-55">

                                <div className="button-items">

                                    <button type="button" className="btn  btn-primary" onClick={this.uploadFile} disabled={isUploading}>
                                        {
                                            isUploading ?
                                                <>
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    <span className="visually-hidden">  Uploading...</span>
                                                </>
                                                : "Upload"
                                        }
                                    </button>
                                    <button type="button" className="btn btn-white" onClick={this.resetSelection}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            );
        }


    }

export default UploaderServiceability