import React from 'react'
import {Route} from 'react-router-dom'

import cargoDashboard from '../cargo/CargoDashboard/cargoDashboard.js'
import { rolePermissions } from '../Config/RoleModulePermissions.js'
import shipmentCargo from '../cargo/shipmentCargo/shipmentCargo.js';
import CargoUpload from '../cargo/uploadCargo/uploadCargo.js';
import TrackingOrders from '../cargo/TrackingOrders/TrackingOrders.js';
import B2BTrackingActivity from '../cargo/components/B2BTrackingActivity.js';
import UploaderServiceability from '../cargo/serviceability/uploader_serviceability.js';
import Serviceability from '../cargo/serviceability/serviceability.js';
import Appointment from '../cargo/appointment/appointment.js';
import UploaderABD from '../cargo/appointment/uploader_appointment.js';

const scrollToTop = () => {
	document.documentElement.scrollTop = 0
}

const CargoRoutes = (props) => {
	scrollToTop()
	// const role = props?.loggedInUser?.role?._id?.name?.trim()?.toLowerCase();
	const role = props.loggedInUser?.role?._id
	let permissions = role?.active && role?.permissions || {};
	permissions = {...rolePermissions, ...permissions};

    const {
        dashboard = {},
    } = permissions;

	// console.log("permissions", permissions);

	return  (
		<React.Fragment>
		

			{/** cargoDashboard section start */}
			{
				dashboard.show &&
				<Route path='/cargo/cargo_dash' exact component={cargoDashboard} />
			}
			{/** cargoDashboard section end */}

            {/** cargoDashboard section start */}
			{
				dashboard.show &&
				<Route path='/cargo/shipmentList' exact component={shipmentCargo} />
			}
			{/** CargoUpload section end */}

			{/** CargoUpload section start */}
			{
				dashboard.show &&
				<Route path='/cargo/uploadShipment' exact component={CargoUpload} />
			}
			{/** CargoUpload section end */}

			{/** CargoUpload section start */}
			{
				dashboard.show &&
				<Route path='/cargo/tracking_orders' exact component={TrackingOrders} />
			}
			{/** CargoUpload section end */}

			{/* {
				<>
					<Route path='/cargo/OrderActivity/:orderId' exact component={B2BTrackingActivity} />
				</>
			} */}
			{
				<>
					<Route path='/cargo/serviceability/upload' exact component={UploaderServiceability} />
				</>
			}
			{
				<>
					<Route path='/cargo/serviceability' exact component={Serviceability} />
				</>
			}
			{
				<>
					<Route path='/cargo/appointment' exact component={Appointment} />
				</>
			}
			{
				<>
					<Route path='/cargo/appointment/ABDupload' exact component={UploaderABD} />
				</>
			}

		</React.Fragment>
	)
}

export default (CargoRoutes);
