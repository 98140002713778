import React, { Component } from "react";
import { connect } from "react-redux";
import { Pie } from "react-chartjs-2";
import moment from "moment";
import { getCurrentMonthOfWeek } from "../../../Utils/CommonFunctions";
import appUrl from "../../../Constants/AppUrl";
import axios from "axios";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Pie Chart with Percentages',
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          // Show percentage in the tooltip
          const total = tooltipItem.dataset.data.reduce((acc, curr) => acc + curr, 0);
          const percentage = ((tooltipItem.raw / total) * 100).toFixed(2);
          return `${tooltipItem.label}: ${percentage}`+'%'; // Tooltip showing percentage
        },
      },
    },
    datalabels: {
      // Show percentage on the chart itself
      formatter: function (value, ctx) {
        const total = ctx.dataset.data.reduce((acc, curr) => acc + curr, 0);
        const percentage = ((value / total) * 100).toFixed(2);
        return `${percentage}` +'%'; // Display percentage directly on the chart
      },
      color: 'white', // Text color for percentages
      font: {
        weight: 'bold',
      },
    },
  },
};

class PieChart extends Component {

  constructor(props) {
    super(props); 
    this.state = {
      data: {},
      NoData: false,
    };
  }

  componentDidMount() {
    axios
      .get(`${appUrl.B2B_DASHBOARD_PIECHART_URL}`)
      .then((response) => {
        const labels = response.data.data.labels;
        const dataset = response.data.data.datasets[0].data;
        const pieData = {
          labels: labels,
          datasets: [
            {
              data: dataset, // Dataset values
              backgroundColor: ['rgba(255, 99, 132, 0.7)', 'rgba(54, 162, 235, 0.7)', 'rgba(255, 159, 64, 0.7)', 'rgba(75, 192, 192, 0.7)'],
              hoverBackgroundColor: ['darkred', 'darkblue', 'rgba(206, 152, 98, 0.7)', 'darkgreen'],
            },
          ],
        };

        if(pieData.datasets[0].data.length===0){
          this.setState({ data: pieData,   NoData: true })
        }else{
          this.setState({ data: pieData, NoData: false  })
        }

        
      })
      .catch((err) => {
        this.setState({ error: err.message, loading: false, NoData: true });
      });
  }


  render() {

    const { data, NoData } = this.state;
    if (NoData) return <p className="p-10" style={{textAlign:"center"}}>No Data Available</p>;
  
    return (
      <div style={{ width: '100%', height:"90%", margin: '0 auto' }}>
        <Pie data={data} options={options} />
      </div>
    );
  };
}

export default PieChart;
