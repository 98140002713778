const ACTIONS = {
    CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAIL: "CREATE_ORDER_FAIL",

    UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",
    UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
    UPDATE_ORDER_FAIL: "UPDATE_ORDER_FAIL",

    SELECT_COURIER_PARTNER: "SELECT_COURIER_PARTNER",
};

export default ACTIONS;
