import React,{useState} from 'react'

import {NavLink} from 'react-router-dom';

import ShowContactUs from '../HomeChunks/ShowContactUs'

const WebFooter =  (props, ref) => {

    const [showContact, setshowContact] = useState(false)


    const showContactUs = () =>{
        setshowContact(!showContact)
    }

        return (
            <>
            <footer>
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-12">
                                <div className="theme_bg_color p-sm-5 p-3 mb-3 contact_position">
                                    <div className="row" style={{alignItems:'center'}}>
                                        <div className="col-sm-4 mb-sm-0 mb-2">
                                                <h2>Contact Details</h2>
                                        </div>
                                        {/* <div className="col-sm-4 mb-sm-0 mb-2">
                                            <div className="phoneIcon">
                                                +91 888-2780086
                                                <br></br>
                                                <span></span>
                                            </div>
                                        </div> */}

                                        <div className="col-sm-4 mb-sm-0 mb-2">
                                            <div className="phoneIcon whatsAppIcon">
                                                +91 888-2780086<span></span>
                                                <br></br>
                                                <span>Call &amp; WhatsApp</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-sm-0 mb-2">
                                            <div className="phoneIcon emailIcon">
                                                hello@yolojet.com
                                                <br></br>
                                                <span>Write to us.</span>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-12 mt-3">
                                <div>
                                    <img src="../img/logo1.png" alt="yolojet" style={{height: '39px',marginBottom: '10px'}} />
                                </div>
                                <p className="text-gray">We help business through our technology powered, automated solution. get freedom to
                                    compare multiple carriers, select and ship, at
                                    the best rates and quickest time.
                                </p>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12 mt-3">
                                        <div>
                                    <img src="../img/gplay.png" alt="gplay" width="127px" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 mt-3">
                                        <div className='magento-icon-container'>
                                    <img src="../img/magento.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 mt-3">
                                        <div className='shopify-icon-container'>
                                    <img src="../img/shophy.png" alt="" />
                                        </div>
                                    </div>

                                </div> 
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-3 col-5 mt-5">
                                <ul className="pl-0">
                                    <li>
                                        <NavLink to="/AboutUs" target="_blank" rel="noopener noreferrer" className="text-gray nav-link">About Us</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="/Career" rel="noopener noreferrer" className="text-gray nav-link">Careers</NavLink>

                                    </li>
                                     <li>
                                        <NavLink to="#" target="_blank" rel="noopener noreferrer" className="text-gray nav-link">Services</NavLink>

                                    </li> 
                                    <li>
                                        <a href="#" onClick={showContactUs} rel="noopener noreferrer" className="text-gray nav-link">Support</a>

                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-5 col-sm-5 col-7 mt-5">
                                <ul className="pl-0">
                                    <li>
                                        <NavLink to="PrivacyPolicy" rel="noopener noreferrer" className="text-gray nav-link">Privacy policy</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="TermsAndConditions" rel="noopener noreferrer" className="text-gray nav-link">Terms & Conditions</NavLink>

                                    </li>
                                     <li>
                                        <NavLink to="#" target="_blank" rel="noopener noreferrer" className="text-gray nav-link">Sitemap</NavLink>

                                    </li> 
                                    <li>
                                    <a href="#" onClick={showContactUs} rel="noopener noreferrer" className="text-gray nav-link">Contact Us</a>

                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-4 col-12 mt-5 footer_add">
                                <p><a href="tel:07314970648" className="text-gray">+91 888-2780086</a></p>
                                <p><a href="mailto:hello@yolojet.com" className="text-gray">hello@yolojet.com</a></p>
                                <div className="address text-gray">
                                    <p>
                                        <strong>Registered Office address :</strong><br />
                                        <span className="pl-0">
                                        Yolojet Internet Services Private Limited
                                        3rd Floor, Tower-A, Logix Cyber Park, 306A,305, Noida, Uttar Pradesh 201301
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Corporate Office address :</strong><br />
                                        <span className="pl-0"> 
                                        Yolojet Internet Services Private Limited
                                        3rd Floor, Tower-A, Logix Cyber Park, 306A,305, Noida, Uttar Pradesh 201301
                                        </span>
                                    </p>
                                    {/* <p>
                                        <strong>Nepal :</strong><br />
                                        <span class="pl-0"> Yolojet, Office No.205, Trade Tower Building, Kathmandu, Madhyamanchal, 06530</span>
                                    </p> */}

                                    <p>
                                    <br />
                                    <strong> North America :</strong><br /><br />
                                    <span className="pl-0">
                                        <b>Atlanta :</b> 100 North Point Center East Suite
                                        125 & 200Alpharetta, GA 30022 <br />
                                        
                                        <b>Arizona :</b> 3125 South Price Road
                                        Suite 122 Chandler, AZ 85248 <br />
                                        
                                        
                                        <b>Canada :</b> 208-120 Traders Blvd E
                                        Mississauga ON L4Z 2H7,  Canada <br />
                                        
                                        
                                        <b>Virginia :</b>1920 Association Drive,
                                        Suite 500, Reston VA 20191 <br />
                                    </span>
                                    </p>
                                   


                                    

                                </div>
                                <div className="text-gray" style={{cursor: "pointer"}}>
                                    <a href="https://www.facebook.com/Yolojet-104761428212956" className="text-gray" target="_blank">
                                    <span><i className="mdi mdi-facebook" aria-hidden="true"></i> &nbsp; &nbsp; </span>
                                    </a>
                                      {/*<a href="https://www.linkedin.com/company/yolojet-internet-services-pvt-ltd/" className="text-gray" target="_blank">
                                    <span><i className="mdi mdi-linkedin" aria-hidden="true"></i> &nbsp; &nbsp; </span>
                                    </a>*/}
                                      <a href="https://twitter.com/yolojet" className="text-gray" target="_blank">
                                    <span><i className="mdi mdi-twitter" aria-hidden="true"></i> &nbsp; &nbsp; </span>
                                    </a>
                                      <a href="https://www.instagram.com/yolojet/" className="text-gray" target="_blank">
                                    <span><i className="mdi mdi-instagram" aria-hidden="true"></i> &nbsp; &nbsp; </span>
                                    </a>
                                </div>
                                <div className="text-gray mapicon mt-3">
                                    <a href="https://www.google.com/maps/place/Yolojet+Internet+Services+Pvt+Ltd/@22.7534141,75.8972057,15z/data=!4m5!3m4!1s0x0:0xa9134b324dba6984!8m2!3d22.7534141!4d75.8972057" target="_blank" className="text-gray">
                                    <span className="bg-white"><img src="img/mapicon.png" alt="" /></span>  &nbsp; Visit our Office
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
            </footer>
            <section style={{background:'#d9d9d9'}} className="pt-4 pb-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-gray text-center">
                            <p>With ❤️ from Yolojet</p>
                            <p>Copyright 2024 © Yolojet Pvt. Ltd. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>

            <ShowContactUs
          show={showContact}
          handleClose={showContactUs}
          />
            </>
        )
    }


export default WebFooter
