import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";

import { Breadcrumb, Modal } from "react-bootstrap";
import Select from "react-select";
import FilePreviewModal from "../../FilePreview/filePreviewModal";
import FilePreviewWrapper from "../../FilePreview/filePreviewWrapper";
import CancelOrderModal from "../../Order/Components/CancelOrderModal";
import CreateRtoOrderModal from "../../Order/Components/CreateRtoOrderModal";
import { fetchAllCustomersRequest } from "../../Users/Duck/UsersActions";
import { searchAllB2BOrderRequest, searchAllOrderRequest, selectOrdersRequest } from "../../Order/Duck/OrderActions";
import B2BOrderItem from "../components/B2BOrderList"
import CargoTableContainer from "../components/cargoTableContainer";

const statusMapping = [
"Created",
"In-transit",
"Reach at Destination",
"Out for Delivery (OFD)",
"Delivered",
"RTO delivered"
]; 


class ShipmentCargo extends Component {
  constructor(props) {
    super(props);

    let filter = props?.filter;

    this.state = {
      pickupDateFrom:
        (filter?.pickupDateFrom && moment(filter?.pickupDateFrom).toDate()) || null,
      toDate:
        (filter?.created_to && moment(filter?.created_to).toDate()) || null,
        add:
        (filter?.add && moment(filter?.add).toDate()) || null,
      pickupDateTo: (filter?.pickupDateTo && moment(filter?.pickupDateTo).toDate()) || null,
      orderType: filter?.order_type || "all",
      awbNumber: filter?.awb || "",
      partners: filter?.deliveryPartner || "",
      status: filter?.fullStatus || "",
      payment_method: filter?.payment_method || "",
      payment_status: filter?.payment_status || "complete",
      logistic_id: filter?.logistic_id || "",
      plan: filter?.plan || "",
      created_by: filter?.created_by || "",
      orderId: null,
      error: null,
      showPreviewOfFileId: null,
      allAttachments: [],
      filter: JSON.stringify({ pathname: props?.location?.pathname }),
      showCancelOrderModal: false,
      showCreateRtoOrderModal: false,
      order: null,
      clientName: null,
    };
    this.selectDateFrom = this.selectDateFrom.bind(this);
    this.selectDateTo = this.selectDateTo.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let { printSelection, selectOrders, fetchAllCustomers, role, permissions } =
      this.props;

    this.loadMoreOrders();
    selectOrders && selectOrders(printSelection, false);
    if (
      (permissions?.showAllOrders || role?.role_id === "vendor") &&
      fetchAllCustomers
    ) {
      fetchAllCustomers();
    }
  }

  togglePreview = (fileId, attachments) => {
    this.setState({ showPreviewOfFileId: fileId, allAttachments: attachments });
  };

  loadMoreOrders = (offset = 0, limit = 100) => {
    let {
      pickupDateFrom,
      fromDate,
      toDate,
      pickupDateTo,
      shipDate,
      orderType,
      payment_method,
      payment_status,
      logistic_id,
      plan,
      created_by,
      awbNumber,
      partners,
      clientName,
      add,
      status,
    } = this.state;
    let { fetchAllOrders, orderList, orderMeta, location } = this.props;

    if (offset === 0 || orderMeta?.total_count > orderList?.length) {
      let params = {
        pathname: location?.pathname,
        offset: offset,
        limit: limit,
      };

      if (pickupDateFrom)
        params.pickupDateFrom = moment(pickupDateFrom).format("YYYY-MM-DD");
      if (toDate)
        params.created_to = moment(toDate).endOf("day").utc().format();
      if (add)
        params.add = moment(add).endOf("day").utc().format();
      if(pickupDateTo)
        params.pickupDateTo = moment(pickupDateTo).format("YYYY-MM-DD");
      if (orderType && orderType !== "all") params.order_type = orderType;
      if (payment_method && payment_method !== "all")
        params.payment_method = payment_method;
      // if (payment_status && payment_status !== "all")
      // params.payment_status = payment_status;
      if (logistic_id && logistic_id !== "all")
        params.logistic_id = logistic_id;
      if (plan) params.plan = plan;
      if (created_by && created_by !== "all") params.created_by = created_by;
      if (awbNumber) params.waybillNo = awbNumber.trim();
      if (clientName) params.clientName = clientName.trim();
      if (partners) params.deliveryPartner = partners.trim();
      if (status) params.fullStatus = status.trim()
      fetchAllOrders && fetchAllOrders(params);
    }
  };

  selectDateFrom = (date) => {
    if (
      this.state.toDate !== null &&
      moment.utc(date).isAfter(moment.utc(this.state.toDate))
    ) {
      // alert('From date should not be greater than to date')
      this.setState({ error: "From date should not be greater than to date" });
      return false;
    }
    this.setState({
      pickupDateFrom: date,
      error: null,
    });
  };

  selectPickupDate = (date) => {
    this.setState({
      pickupDateTo: date,
      error: null,
    });
  };


  selectadd = (date) => {
   
    this.setState({
      add: date,
      error: null,
    });
  };


  selectDateTo = (date) => {
    if (
      this.state.pickupDateFrom !== null &&
      moment.utc(date).isBefore(moment.utc(this.state.pickupDateFrom))
    ) {
      // alert('To date should not be less than from date')
      this.setState({ error: "To date should not be less than from date" });
      return false;
    }
    this.setState({
      pickupDateFrom: date,
      error: null,
    });
  };

  handleAWB = (e) => {
    this.setState({ awbNumber: e?.target?.value });
  };

  handleClientName = (e) => {
    this.setState({ clientName: e?.target?.value });
  }

  handleClose() {
    this.setState({
      showDispatch: false,
    });
  }

  handleOrderType = (e) => {
    this.setState({ orderType: e?.target?.value });
  };

  onFilterOrder = () => {
    this.setState({
      error: null,
    });

    this.loadMoreOrders();
  };

  handleChange = (name) => (e) => {
    let value = e?.target?.value || e?.value;

    this.setState({
      [name]: value,
    });
  };

  handleDP = (e) => {
    this.setState({ partners: e?.target?.value });
  };

  handleStatus = (e) => {
    this.setState({ status: e?.target?.value });
  };


  reset = () => {
    this.setState(
      {
        pickupDateFrom: null,
        toDate: null,
        add: null,
        pickupDateTo:null,
        orderType: "",
        payment_method: "",
        payment_status: "complete",
        logistic_id: "",
        plan: "",
        created_by: "",
        awbNumber: "",
        partners: "",
        status:"",
        error: null,
        clientName: ""
      },
      () => {
        this.onFilterOrder();
      }
    );
  };

  onShowPrintPreview = (clickCheck) => {
    let { history } = this.props;
    //window.print();
    history.push({
      pathname: "/app/ShipmentPrintModal/",
      state: { clickCheckProp: clickCheck },
    });
  };

  onDeselectAll = () => {
    let { printSelection, selectOrders } = this.props;
    selectOrders && selectOrders(printSelection, false);
  };

  handleCancelOrderModal = (show = false, order) => {
    show = typeof show === "boolean" && show;
    this.setState({
      showCancelOrderModal: show,
      order,
    });
  };

  handleCreateRtoOrderModal = (show = false, order) => {
    show = typeof show === "boolean" && show;
    this.setState({
      showCreateRtoOrderModal: show,
      order,
    });
  };

  render() {
    let {
      orderList,
      orderMeta,
      loading,
      role,
      logistics,
      plans,
      planOrder,
      filter,
      printSelection,
      selectOrders,
      customers,
      permissions,
    } = this.props;
    let pendingCount = orderMeta?.total_pending_count || 0;
    let totalAmount = orderMeta?.total_amount || 0;
    let individualCount = orderMeta?.total_individual_count || 0;
    let bulkUploadCount = orderMeta?.total_bulk_upload_count || 0;
    let bulkOrderCount = orderMeta?.total_bulk_order_count || 0;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                  All Shipments
                  </h2>
                  <Breadcrumb title={["Shipment", "Shipment List"]} />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <h4 className="card-title">Filter</h4>
              <div className="card-body">
                <form>
                  <div className="row order_filter">
                    <div className="col-md-3 col-sm-4 col-12">
                    <label style={{ display: 'flex', alignItems: 'center'}}>
                        <DatePicker
                          selected={this.state.pickupDateFrom}
                          onChange={this.selectDateFrom}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="Pickup Date From"
                          isClearable
                          style={{ flex: 1 }}
                        />
                        {!this.state.pickupDateFrom && (
                          <span style={{ marginLeft: '4px',fontSize: '18px' }}>
                            <i className="bx bx-calendar-alt"></i>
                          </span>
                        )}
                      </label>
                      
                    </div>
                    <div className="col-md-3 col-sm-4 col-12">
                    <label style={{ display: 'flex', alignItems: 'center'}} >
                        <DatePicker
                          selected={this.state.pickupDateTo}
                          onChange={this.selectPickupDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="Pickup Date To"
                          isClearable
                          style={{ flex: 1 }}
                        />
                        {!this.state.pickupDateTo && (
                          <span style={{ marginLeft: '4px',fontSize: '18px' }}>
                            <i className="bx bx-calendar-alt"></i>
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="col-md-3 col-sm-4 col-12">
                    <label style={{ display: 'flex', alignItems: 'center'}}>
                        <DatePicker
                          selected={this.state.add}
                          onChange={this.selectadd}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          placeholderText="Actual Delivery Date"
                          isClearable
                          style={{ flex: 1 }}
                        />
                        {!this.state.add && (
                          <span style={{ marginLeft: '4px',fontSize: '18px' }}>
                            <i className="bx bx-calendar-alt"></i>
                          </span>
                        )}
                      </label>
                    </div>

                    {
                      logistics?.length > 0 &&
                      <div className="col-md-3 col-sm-3 col-12">
                        <select
                          className="form-control"
                          value={this.state.status}
                          onChange={this.handleStatus}
                        >
                          <option value="all">Status</option>
                          {statusMapping &&
                            statusMapping.map((cp, index) => (
                              <option key={index} value={cp}>
                                {cp}
                              </option>
                            ))}
                        </select>
                      </div>
                    }


                    {
                      logistics?.length > 0 &&
                      <div className="col-md-3 col-sm-3 col-12">
                        <select
                          className="form-control"
                          value={this.state.partners}
                          onChange={this.handleDP}
                        >
                          <option value="all">All Partners</option>
                          <option key="1" value="gati">
                            Gati
                          </option>
                          <option key="2" value="ekart">
                            Ekart
                          </option>
                          <option key="2" value="delhivery">
                            Delhivery
                          </option>
                          <option key="2" value="safeship">
                            Safeship
                          </option>
                        </select>
                      </div>
                    }
                    {(role?.role_id === 'administrator' || role?.role_id === 'admin') &&
                    <div className="col-md-3 col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Client Name"
                      onChange={this.handleClientName}
                      value={this.state.clientName}
                      autoComplete="on"
                    />
                  </div>}
                    
                    <div className="col-md-3 col-sm-5">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter waybill number"
                        onChange={this.handleAWB}
                        value={this.state.awbNumber}
                        autoComplete="on"
                      />
                    </div>

                    <div className="col-md-2 col-sm-4">
                      <div className="filter_btns d-flex align-items-start">
                        <button
                          type="button"
                          className="btn btn-primary mr-2 btn-icon-text"
                          onClick={this.onFilterOrder}
                          style={{ width: 80 }}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-icon-text"
                          onClick={this.reset}
                          style={{ width: 80 }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {this.state.error && (
                  <div className="mt-1" style={{ color: "red" }}>
                    {this.state.error}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.showPreviewOfFileId && (
          <FilePreviewModal toggleModal={this.togglePreview}>
            <FilePreviewWrapper
              data={this.state.allAttachments}
              showPreviewOfFileId={this.state.showPreviewOfFileId}
              togglePreview={this.togglePreview}
            />
          </FilePreviewModal>
        )}

        {(role?.role_id === 'administrator' || role?.role_id === 'admin') && <CargoTableContainer
          headings={[
            "AWB Number",
            "Client Name",
            "Delivery partner",
            "Pickup Location",
            "Drop Location",
            "Weight",
            "Status",
            "Pickup Date",
            "add",
            "Appointment Date",
            "Action",
          ]}

          showSelectAll={true}
          totalEntries={orderMeta?.totalCount || orderMeta?.total_count}
          rowData={orderList}
          renderRow={(item, index) => (
            <B2BOrderItem
              id={item}
              index={index}
              showSelect
              showCancelOrder
              handleCancelOrderModal={this.handleCancelOrderModal}
              handleCreateRtoOrderModal={this.handleCreateRtoOrderModal}
              {...this.props}
            />
          )}
          showSearch={false}
          loading={loading}
          filter={filter}
          loadMore={this.loadMoreOrders}
          actionBtnText={"Label Preview"}
          ThermalactionBtnText={"Thermal Label Preview"}
          showActionBtn={printSelection?.length > 0}
          onClickAction={this.onShowPrintPreview}
          onClickDeselectAction={this.onDeselectAll}
          onSelectAll={selectOrders}
          selectedList={printSelection}
        />}
        
        {(role?.role_id === 'Client' || role?.role_id === 'client') && 
        <CargoTableContainer
        headings={[
          "AWB Number",
          "Delivery partner",
          "Pickup Location",
          "Drop Location",
          "Weight",
          "Status",
          "Pickup Date",
          "EDD",
          "Appointment Date",
          "Action",
        ]}

        showSelectAll={true}
        totalEntries={orderMeta?.totalCount || orderMeta?.total_count}
        rowData={orderList}
        renderRow={(item, index) => (
          <B2BOrderItem
            id={item}
            index={index}
            showSelect
            showCancelOrder
            handleCancelOrderModal={this.handleCancelOrderModal}
            handleCreateRtoOrderModal={this.handleCreateRtoOrderModal}
            {...this.props}
          />
        )}
        showSearch={false}
        loading={loading}
        filter={filter}
        loadMore={this.loadMoreOrders}
        actionBtnText={"Label Preview"}
        ThermalactionBtnText={"Thermal Label Preview"}
        showActionBtn={printSelection?.length > 0}
        onClickAction={this.onShowPrintPreview}
        onClickDeselectAction={this.onDeselectAll}
        onSelectAll={selectOrders}
        selectedList={printSelection}
      />}
        

        <CancelOrderModal
          show={this.state.showCancelOrderModal}
          onHide={this.handleCancelOrderModal}
          order={this.state.order}
        />

        <CreateRtoOrderModal
          show={this.state.showCreateRtoOrderModal}
          onHide={this.handleCreateRtoOrderModal}
          order={this.state.order}
        />

        <Modal
          show={this.state.showDispatch}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={true}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Dispatch Label Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 text-center">
                <img src="/images/dispatch-lable.jpg" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              className="btn btn-secondary"
              onClick={this.handleClose}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { location } = ownProps;
  let pathname = location?.pathname;

  let users = state.users?.users;
  let customers = state.users?.customerList?.map((userId) => ({
    value: userId,
    label: users[userId]?.email,
  }));

  let logistics = state.logistics?.data;

  // let orderState = state.order;
  console.log(state.order?.filters, "state.order?.filters")
  let filter = state.order?.filters[pathname];
  console.log(JSON.stringify(filter), "mBoard")
  let boards = state.order?.boards;
  console.log(boards, "boards")
  let mBoard = boards[JSON.stringify(filter)];
  console.log(mBoard, "mBoard")
  let orderList = mBoard?.list;
  let orderMeta = mBoard?.meta;
  let loading = mBoard?.loading;
  let error = mBoard?.error;

  let printSelection = state.order?.printSelection;

  let loggedInUser = state?.loggedInUser?.data?.data;
  const mVisiblePlans = loggedInUser?.visible_plans || [];
  let role = loggedInUser?.role?._id;
  let permissions = (role?.active && role?.permissions) || {};

  let selected_partners = loggedInUser?.selected_partners || [];
  if (!permissions?.showAllOrders && selected_partners?.length > 0) {
    logistics = logistics?.filter((logistic) =>
      selected_partners.includes(logistic?._id)
    );
  }

  let planOrder = state?.plan?.planOrder;
  if (Array.isArray(planOrder) && mVisiblePlans?.length > 0) {
    planOrder = mVisiblePlans;
  }


  return {
    filter: filter,
    orderList: orderList,
    orderMeta: orderMeta,
    loading: loading,
    error: error,
    logistics: logistics,
    customers: customers,
    printSelection: printSelection,
    role: role,
    permissions: permissions,
    plans: state?.plan?.plans,
    planOrder: planOrder,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllOrders: (prams) => dispatch(searchAllB2BOrderRequest(prams)),
    fetchAllCustomers: (prams) => dispatch(fetchAllCustomersRequest(prams)),
    selectOrders: (ids, isSelected) =>
      dispatch(selectOrdersRequest(ids, isSelected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCargo);
